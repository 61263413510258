import React from 'react'
import CommingSoon from '../../Components/CommingSoon'
import useDocumentTitle from '../../Hooks/PageTitle'

const ComingSoonPage = () => {
  useDocumentTitle("TRoo Traveling | Coming Soon Page")
  return (
    <div>
        <CommingSoon/>
    </div>
  )
}

export default ComingSoonPage