import React from "react";
import arrowSpiral from "../images/ArrowSpiral.png";
import { Link } from "react-router-dom";

const ScarchBox = () => {
  return (
    <div className="main_container p-10 scarchbox bg-troo-white relative z-10 mt-[-100px] rounded">
      <div className="flex justify-between">
        <h3>Search Trips & Tours</h3>
        <img src={arrowSpiral} alt="arrowSpiral" />
      </div>
      <div className="md:flex justify-between gap-5 md:gap-0">
        <div>
          <p className="mb-4">Tour Destination</p>
          <select className="rounded px-5 py-3 w-full md:w-[200px] lg:w-[250px] xl:w-[320px]">
            <option value="Hill City, poland">Hill City, poland</option>
            <option value="Barcelona City Beach">Barcelona City Beach</option>
            <option value="Mountain Hiking Tour">Mountain Hiking Tour</option>
          </select>
        </div>
        <div>
          <p className="mb-4">Total Person</p>
          <select className="rounded px-5 py-3 w-full md:w-[160px] lg:w-[250px] xl:w-[320px]">
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
          </select>
        </div>
        <div>
          <p className="mb-4">Tour Date</p>
          <input
            className="rounded px-5 py-3 w-full md:w-[200px] lg:w-[250px] xl:w-[320px]"
            type="date"
          />
        </div>
        <div className="flex items-end mt-8 md:mt-0">
          <Link to="/contact-us" className="btn3">Contact With Us</Link>
        </div>
      </div>
    </div>
  );
};

export default ScarchBox;
