import React from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { TbPhoneCall } from "react-icons/tb";
import { RiMailOpenLine } from "react-icons/ri";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../images/Logo.svg";

const Footer = () => {
  return (
    <div className="footer">
      <div className="main_container w-full pt-24">
        <div className="grid grid-cols-1 lg:grid-cols-2 px-10 py-8 gap-6 lg:gap-8 xl:gap-16 bg-troo-borderclr mb-12 w-full">
          <div className="flex flex-col md:flex-row justify-between gap-8 xl:gap-16">
            <div className="flex md:justify-between gap-3">
              <div className="bg-troo-primary w-10 h-10 flex items-center justify-center rounded">
                <HiOutlineLocationMarker className="text-[30px]" />
              </div>
              <div className="text-troo-white">
                <h4>Address</h4>
                <p>Riverside 255,San Francisco, USA</p>
              </div>
            </div>
            <div className="flex md:justify-between  gap-3">
              <div className="bg-troo-primary w-10 h-10 flex items-center justify-center rounded">
                <TbPhoneCall className="text-[30px]" />
              </div>
              <div className="text-troo-white">
                <h4>Call Us on</h4>
                <p>+44 123 456 7890</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between gap-5"> 
            <div className="flex  gap-3">
              <div className="bg-troo-primary w-10 h-10 flex items-center justify-center rounded">
                <RiMailOpenLine className="text-[30px]" />
              </div>
              <div className="text-troo-white">
                <h4>Email Us</h4>
                <p>info@troothemes.com</p>
              </div>
            </div>
            <div className="flex gap-2 items-center text-troo-white">
              <p>We Are on :</p>
              <Link className="p-1 hover:bg-troo-primary rounded">
                <FaFacebookF />
              </Link>
              <Link className="p-1 hover:bg-troo-primary rounded">
                <FaInstagram />
              </Link>
              <Link className="p-1 hover:bg-troo-primary rounded">
                <FaLinkedinIn />
              </Link>
              <Link className="p-1 hover:bg-troo-primary rounded">
                <FaTwitter />
              </Link>
            </div>
          </div>
        </div>
        <div className="my-12 grid grid-cols-1 lg:grid-cols-2 gap-7 w-full lg:gap-12">
          <div className="flex flex-col md:flex-row justify-between gap-10 text-troo-white">
            <div className="flex flex-col gap-5">
              <Link>
                <img src={logo} alt="logo" />
              </Link>
              <p className="md:w-[400px] w-full">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable.
              </p>
              <h4>Monday to Saturday: 10: 00 AM to 06:00 PM</h4>
              <h4>Sunay: Closed</h4>
            </div>
            <div>
              <h3 className="mb-8">Useful Links</h3>
              <nav className="flex flex-col gap-4">
                <Link>About</Link>
                <Link>Our Tour Package</Link>
                <Link>Our Blogs</Link>
                <Link>FAQ’s</Link>
                <Link>Customer Review</Link>
                <Link>Contact Us</Link>
              </nav>
            </div>
          </div>
          <div className="flex md:flex-row flex-col gap-8 justify-between text-troo-white">
            <div>
              <h3 className="mb-8">Destination</h3>
              <div className="flex flex-col gap-2">
                <p>Beauty of India</p>
                <p>Grand Japan</p>
                <p>Dragon China</p>
                <p>Bhutan Nature</p>
                <p>Carebian Sea</p>
                <p>Amazing Srilanka</p>
              </div>
            </div>
            <div className="border-8  rounded border-troo-whitefade2">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.8850349918985!2d72.49642611542339!3d23.027993021903114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b278e046fa5%3A0xd837def63555f791!2sTRooInbound%20Private%20Limited!5e0!3m2!1sen!2sin!4v1674649284683!5m2!1sen!2sin"
                width="100%"
                height="100%"
                title="Gmap"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
