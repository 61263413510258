import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import FAQs2 from '../../Components/FAQs2'
import useDocumentTitle from '../../Hooks/PageTitle'

export const FAQPage = () => {
  useDocumentTitle("TRoo Traveling | FAQs Page")
  return (
    <div>
        <Layout>
            <BannerGlobal/>
            <FAQs2/>
        </Layout>
    </div>
  )
}
