import reviewer1 from "../images/reviewer1.png";
import reviewer2 from "../images/reviewer2.png";
import reviewer3 from "../images/reviewer3.png";
import reviewer4 from "../images/reviewer4.png";

import { FaStar } from 'react-icons/fa';

export const FeedBackData = [
  {
    id: 1,
    img: reviewer1,
    name: "Pansy Stiedemann",
    sub: "Norway Tour",
    para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, passages of Lorem Ipsum available by injected humour, or randomised words.",
    star : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
  },
  {
    id: 2,
    img: reviewer2,
    name: "Coby Collier",
    sub: "Norway Tour",
    para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, passages of Lorem Ipsum available by injected humour, or randomised words.",
    star : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
  },
  {
    id: 3,
    img: reviewer3,
    name: "Alex Fatherson",
    sub: "Nepal Jumgle Tour",
    para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, passages of Lorem Ipsum available by injected humour, or randomised words.",
    star : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
  },
  {
    id: 4,
    img: reviewer4,
    name: "Emma Bossman",
    sub: "African Safari Tour",
    para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, passages of Lorem Ipsum available by injected humour, or randomised words.",
    star : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
  },
  {
    id: 5,
    img: reviewer2,
    name: "Emma Bossman",
    sub: "African Safari Tour",
    para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, passages of Lorem Ipsum available by injected humour, or randomised words.",
    star : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
  },
  {
    id: 6,
    img: reviewer3,
    name: "Emma Bossman",
    sub: "African Safari Tour",
    para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, passages of Lorem Ipsum available by injected humour, or randomised words.",
    star : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
  },
  {
    id: 7,
    img: reviewer1,
    name: "Emma Bossman",
    sub: "African Safari Tour",
    para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, passages of Lorem Ipsum available by injected humour, or randomised words.",
    star : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
  },
  {
    id: 8,
    img: reviewer3,
    name: "Emma Bossman",
    sub: "African Safari Tour",
    para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, passages of Lorem Ipsum available by injected humour, or randomised words.",
    star : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
  },
  {
    id: 9,
    img: reviewer4,
    name: "Emma Bossman",
    sub: "African Safari Tour",
    para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, passages of Lorem Ipsum available by injected humour, or randomised words.",
    star : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
  },
  {
    id: 10,
    img: reviewer1,
    name: "Emma Bossman",
    sub: "African Safari Tour",
    para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, passages of Lorem Ipsum available by injected humour, or randomised words.",
    star : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
  },

];
