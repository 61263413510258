import React from "react";
import { Link } from "react-router-dom";
import planeleft from "../images/planeleft.png";
import planeRight from "../images/PlaneRight.png";

const Banner = () => {
  return (
    <div className=" top-0 w-full">
      <div className="bg-hero-banner bg-no-repeat bg-cover w-full py-24 relative z-[1] before:content-[''] before:w-full before:h-full before:absolute before:bg-troo-deepblack before:opacity-80 before:top-0 before:left-0 before:z-[-1] ">
        <div className=" absolute left-0 top-[50%] translate-y-[50%] text-left ml-0 hidden lg:block">
          <img
            className="inline-block relative max-w-full"
            src={planeleft}
            alt="planeleft"
          />
        </div>
        <div className="absolute top-[50%] right-0 translate-y-[-50%] text-left ml-0 hidden lg:block">
          <img
            className="inline-block relative max-w-full"
            src={planeRight}
            alt="planeRight"
          />
        </div>
        <div className="main_container py-20 lg:py-32">
          <div className="text-center text-troo-white ">
            <h3 className="bg-troo-whitefade p-4 rounded-full mb-5  inline-block">
              <span className="text-troo-primary">Tours & Holidays</span>{" "}
              Specialists
            </h3>
            <h1 className="max-w-[950px] m-auto mb-5">
              Ready to Explore The Beauty of Wonderful World
            </h1>
            <p className="max-w-[750px]  m-auto mb-10">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour,
            </p>
            <div className="flex-col sm:flex-row flex justify-center gap-5">
              <Link to="/tour-package" className="btn2">
                Explore Tour Package
              </Link>
              <Link to="/contact-us" className="btn1">
                Contact With Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
