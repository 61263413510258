import { HiOutlineLocationMarker, HiOutlineClock } from "react-icons/hi";
import { HiChatBubbleLeftRight } from "react-icons/hi2";

export const ContactBoxData = [
    {
        id :1,
        icon : <HiOutlineLocationMarker/>,
        name : "Visit Us",
        para1 : "1234 TRoo TRoo Avenue,",
        para2 : "Avadaville, 11221"
    },
    {
        id :2,
        icon : <HiChatBubbleLeftRight/>,
        name : "Talk With Us",
        para1 : "+44 123 456 7890,",
        para2 : "contact@troothemes.com"
    },
    {
        id :3,
        icon : <HiOutlineClock/>,
        name : "Opening Hours",
        para1 : "Mon - Satday: 10:00AM to 06:00PM",
        para2 : "Sunday Closed"
    },
]

