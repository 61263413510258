import React from 'react'
import { FeedBackData } from '../Data/FeedBackData'
import { Link } from 'react-router-dom'

const FeedBack2 = () => {
  return (
    <div className="py-12 lg:py-24">
        <div className="main_container">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 mb-10">
          {FeedBackData.map((e, i) => (
            <Link className="p-5 bg-troo-white border border-troo-tertiary shadow-md">
              <div className="sm:flex items-center justify-between mb-5 ">
                <div className="flex gap-3 items-center">
                  <div className="reviewerImg">
                    <img src={e.img} alt={e.img} />
                  </div>
                  <div>
                    <h5 className="mb-2">{e.name}</h5>
                    <h6>{e.sub}</h6>
                  </div>
                </div>
                <div className="justify-end flex gap-1 text-troo-primary">{e.star}</div>
              </div>
              <p className="p-5 bg-troo-bluefade rounded">{e.para}</p>
            </Link>
          ))}
        </div>
        </div>
    </div>
  )
}

export default FeedBack2