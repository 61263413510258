import React from "react";
import { Link } from "react-router-dom";
import { FeedBackData } from "../Data/FeedBackData";

const Feedback = () => {
  return (
    <div className="bg-feedback-bg bg-no-repeat w-full bg-center py-12 lg:py-24">
      <div className="main_container">
        <div className="text-center mb-10">
          <p className="bg-troo-headingbg py-2 px-6 rounded-full mb-5  inline-block">
            Our Traveler’s Feedback
          </p>
          <h2 className=" max-w-[500px] m-auto">
            What Our
            <span className="text-troo-secondary"> Happy Traveler </span> Says
            About us
          </h2>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 mb-10">
          {FeedBackData.slice(0,4).map((e, i) => (
            <Link className="p-5 bg-troo-white border border-troo-tertiary shadow-md" key={i}>
              <div className="sm:flex items-center justify-between mb-5 ">
                <div className="flex gap-3 items-center">
                  <div className="relative z-[1] before:content-[''] before:w-full before:shadow-none before:h-full before:absolute before:left-0 before:top-[-5px] before:z-[-1] before:bg-troo-secondary before:rounded-[50%] transition-all after:content-[''] after:bg-quate-icon after:z-[1] after:absolute after:right-0 after:bottom-0 after:bg-no-repeat after:w-[25px] after:h-[25px] after:rounded-[50%] after:bg-center after:transition-all">
                    <img className="rounded-[50%] w-[80px] h-[80px] object-none" src={e.img} alt={e.img} />
                  </div>
                  <div>
                    <h5 className="mb-2">{e.name}</h5>
                    <h6>{e.sub}</h6>
                  </div>
                </div>
                <div className="flex gap-1 text-troo-primary">{e.star}</div>
              </div>
              <p className="p-5 bg-troo-bluefade rounded">{e.para}</p>
            </Link>
          ))}
        </div>
        <div className="text-center">
            <Link to="/testimonial" className="btn4">View All Reviews</Link>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
