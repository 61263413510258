import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import DestinationDetails from '../../Components/DestinationDetails'
import useDocumentTitle from '../../Hooks/PageTitle'

const DestinationDetailsPage = () => {
  useDocumentTitle("TRoo Traveling | Destination Details")
  return (
    <div>
        <Layout>
            <BannerGlobal/>
            <DestinationDetails/>
        </Layout>
    </div>
  )
}

export default DestinationDetailsPage