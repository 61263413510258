import icon1 from "../images/highlight1.png"
import icon2 from "../images/highlight2.svg"
import icon3 from "../images/highlight3.svg"

export const AboutHighlightData = [
    {
        id : 1,
        name : "Our Mission",
        img :icon1,
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"
    },
    {
        id : 2,
        name : "Our Vision",
        img :icon2,
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"
    },
    {
        id : 3,
        name : "Our Values",
        img :icon3,
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"
    },
]