import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import TourDetails from '../../Components/TourDetails'
import useDocumentTitle from '../../Hooks/PageTitle'

const TourPackageDetailsPage = () => {
  useDocumentTitle("TRoo Traveling | Tour Packages Details")
  return (
    <div>
        <Layout>
            <BannerGlobal/>
            <TourDetails/>
        </Layout>
    </div>
  )
}

export default TourPackageDetailsPage