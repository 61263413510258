import tour1 from "../images/tour1.png";
import tour2 from "../images/tour2.png";
import tour3 from "../images/tour3.png";
import tour4 from "../images/tour4.png";
import tour5 from "../images/tour5.png";
import tour6 from "../images/tour6.png";
import tour7 from "../images/tour7.png";
import tour8 from "../images/tour8.png";
import tour9 from "../images/tour9.png";
import { CiLocationOn } from "react-icons/ci";
import { LiaCoinsSolid } from "react-icons/lia";
import { FaRegClock, FaRegStar } from "react-icons/fa";

export const TourData = [
  {
    id: 1,
    name: "Family Tour With Dubai!",
    img: tour1,
    star: <FaRegStar />,
    clock: <FaRegClock />,
    coin: <LiaCoinsSolid />,
    location: <CiLocationOn />,
    locationName: "United Arab Emirates ",
    price: "From $670.00",
    disCount: "$890.00",
    day: "4 Days / 5 Nights",
    rating: 4.8,
  },
  {
    id: 2,
    name: "Hill City, poland",
    img: tour2,
    star: <FaRegStar />,
    clock: <FaRegClock />,
    coin: <LiaCoinsSolid />,
    location: <CiLocationOn />,
    locationName: "United Arab Emirates ",
    price: "From $1670.00",
    disCount: "$1190.00",
    day: "2 Days / 3 Nights",
    rating: 4.8,
  },
  {
    id: 3,
    name: "Barcelona City Beach",
    img: tour3,
    star: <FaRegStar />,
    clock: <FaRegClock />,
    coin: <LiaCoinsSolid />,
    location: <CiLocationOn />,
    locationName: "United Arab Emirates ",
    price: "From $670.00",
    disCount: "$890.00",
    day: "7 Days / 8 Nights",
    rating: 4.8,
  },
  {
    id: 4,
    name: "Mountain Hiking Tour",
    img: tour4,
    star: <FaRegStar />,
    clock: <FaRegClock />,
    coin: <LiaCoinsSolid />,
    location: <CiLocationOn />,
    locationName: "United Arab Emirates ",
    price: "From $670.00",
    disCount: "$890.00",
    day: "4 Days / 5 Nights",
    rating: 4.8,
  },
  {
    id: 5,
    name: "Train Tour Skyline",
    img: tour5,
    star: <FaRegStar />,
    clock: <FaRegClock />,
    coin: <LiaCoinsSolid />,
    location: <CiLocationOn />,
    locationName: "United Arab Emirates ",
    price: "From $670.00",
    disCount: "$890.00",
    day: "7 Days / 8 Nights",
    rating: 4.8,
  },
  {
    id: 6,
    name: "Forest Wild Life",
    img: tour6,
    star: <FaRegStar />,
    clock: <FaRegClock />,
    coin: <LiaCoinsSolid />,
    location: <CiLocationOn />,
    locationName: "United Arab Emirates ",
    price: "From $670.00",
    disCount: "$890.00",
    day: "14 Days / 15 Nights",
    rating: 4.8,
  },
  {
    id: 7,
    name: "Historic Port of Hamburg",
    img: tour7,
    star: <FaRegStar />,
    clock: <FaRegClock />,
    coin: <LiaCoinsSolid />,
    location: <CiLocationOn />,
    locationName: "United Arab Emirates ",
    price: "From $670.00",
    disCount: "$890.00",
    day: "14 Days / 15 Nights",
    rating: 4.8,
  },
  {
    id: 8,
    name: "History Of Amsterdam",
    img: tour8,
    star: <FaRegStar />,
    clock: <FaRegClock />,
    coin: <LiaCoinsSolid />,
    location: <CiLocationOn />,
    locationName: "United Arab Emirates ",
    price: "From $670.00",
    disCount: "$890.00",
    day: "14 Days / 15 Nights",
    rating: 4.8,
  },
  {
    id: 9,
    name: "Republic of Maldives",
    img: tour9,
    star: <FaRegStar />,
    clock: <FaRegClock />,
    coin: <LiaCoinsSolid />,
    location: <CiLocationOn />,
    locationName: "United Arab Emirates ",
    price: "From $670.00",
    disCount: "$890.00",
    day: "14 Days / 15 Nights",
    rating: 4.8,
  },
];
