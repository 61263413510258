import cat1 from "../images/cat1.png"
import cat2 from "../images/cat2.png"
import cat3 from "../images/cat3.png"
import cat4 from "../images/cat4.png"
import cat5 from "../images/cat5.png"
import cat6 from "../images/cat6.png"
import cat7 from "../images/cat7.png"



export const TravelData = [
    {
        id : 1,
        name : "Beaches",
        img : cat1
    },
    {
        id : 2,
        name : "Beaches",
        img : cat2
    },
    {
        id : 3,
        name : "Beaches",
        img : cat3
    },
    {
        id : 4,
        name : "Beaches",
        img : cat4
    },
    {
        id : 5,
        name : "Beaches",
        img : cat5
    },
    {
        id : 6,
        name : "Beaches",
        img : cat6
    },
    {
        id : 7,
        name : "Beaches",
        img : cat7
    },
]