import React from "react";
import errorImg from "../images/errorImg.png";
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <div className=" errorbg py-12 lg:py-40 ">
      <div className="main_container flex justify-center pb-10">
        <div className="flex flex-col text-center gap-10">
          <h2>Oops! That page can’t be found.</h2>
          <img className="w-full" src={errorImg} alt={errorImg} />
          <h4 className="max-w-[600px] text-center m-auto">
            The page you look for does not exist or another error occured please
            kindly refreash or retun to home page.
          </h4>
          <div className="flex-col sm:flex-row flex justify-center gap-5">
            <Link to="/" className="btn3">Go to Hoempage</Link>
            <Link className="btn4">Go Back</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404;
