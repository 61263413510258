import React, { useEffect, useState } from "react";
import blogDetails from "../images/blogDetails.png";
import { BiSolidQuoteLeft } from "react-icons/bi";
import blogdtl1 from "../images/blogDtl1.png";
import blogdtl2 from "../images/blogDtl2.png";
import { Link, useSearchParams } from "react-router-dom";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { BlogData } from "../Data/BlogData";

const BlogDetails = () => {
    const [heading, setHeading] = useState("")
    const [picture, setPicture] = useState("")
    const [scarchparam] =useSearchParams()
    const id = scarchparam.get("id")
  
    useEffect(()=>{
      const filData = BlogData.find((data)=>data?.id == id)
      setHeading(filData?.name)
      setPicture(filData?.img)
    },[id])
  return (
    <div className="blogDetails py-12 lg:py-24">
      <div className="main_container flex flex-col gap-3">
        <div className="md:flex justify-center py-3 border-y border-troo-bluefade">
          <h4 className="border-r-2 px-5 border-troo-deepblack">
            08 March, 2023
          </h4>
          <h4 className="border-r-2 px-5 border-troo-deepblack">
            By: John Johnathan
          </h4>
          <h4 className="px-5">Tags: Travel, Tour, Advanture</h4>
        </div>
        <h2>{heading || "10 Products You Need To Pack For Your Luxury Summer Travel"}</h2>
        <img className="w-full max-h-[700px]" src={picture || blogDetails} alt={blogDetails} />
        <h3>Our Top Picks</h3>
        <p>
          Lorem Ipsum is simply dummy text of the printin and typesetting
          industry. Lorem Ipsum has been the industry’s standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged.
        </p>
        <h3>Geography of India</h3>
        <p>
          Lorem Ipsum is simply dummy text of the printin and typesetting
          industry. Lorem Ipsum has been the industry’s standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged.
        </p>
        <h3>Seasons in India</h3>
        <p>
          Lorem Ipsum is simply dummy text of the printin and typesetting
          industry. Lorem Ipsum has been the industry’s standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries.
        </p>
        <h3>What About Roll-on Luggage?</h3>
        <p>
          Lorem Ipsum is simply dummy text of the printin and typesetting
          industry. Lorem Ipsum has been the industry’s standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged.
          <br /> Lorem Ipsum is simply dummy text of the printin and typesetting
          industry. Lorem Ipsum has been the industry’s standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries.
        </p>
        <div className=" m-auto">
          <p className="bg-troo-bluefade border-l-4 border-troo-secondary max-w-[600px] p-4">
            <BiSolidQuoteLeft className="text-troo-secondary text-[25px]" />
            Lorem Ipsum is simply dummy text of the printin and typesetting
            industry. Lorem Ipsum has been the industry’s standard dummy text
            ever since the 1500s, when an unknown printer took a galley.
          </p>
        </div>
        <p>
          Lorem Ipsum is simply dummy text of the printin and typesetting
          industry. Lorem Ipsum has been the industry’s standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged.Lorem Ipsum is simply dummy text of
          the printin and typesetting industry. Lorem Ipsum has been the
          industry’s standard dummy text ever since the 1500s, when an unknown
          printer took a galley of type and scrambled.
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          <img className="w-full" src={blogdtl1} alt={blogdtl1} />
          <img className="w-full" src={blogdtl2} alt={blogdtl2} />
        </div>
        <p>
          Lorem Ipsum is simply dummy text of the printin and typesetting
          industry. Lorem Ipsum has been the industry’s standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged.Lorem Ipsum is simply dummy text of
          the printin and typesetting industry.
        </p>
        <div className="p-4 flex items-center gap-1 bg-troo-bluefade rounded shadow-sm">
          <p className="mr-5">Share This Articl</p>
          <Link className="hover:bg-troo-secondary p-2 hover:text-troo-white rounded">
            <FaFacebookF />
          </Link>
          <Link className="hover:bg-troo-secondary p-2 hover:text-troo-white rounded">
            <FaInstagram />
          </Link>
          <Link className="hover:bg-troo-secondary p-2 hover:text-troo-white rounded">
            <FaTwitter />
          </Link>
          <Link className="hover:bg-troo-secondary p-2 hover:text-troo-white rounded">
            <FaLinkedinIn />
          </Link>
        </div>
        <h3 className="mt-7">Comments (0)</h3>
        <h4>Leave A Comment</h4>
        <form className="mt-6">
              <div className="flex flex-col gap-y-8">
                <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-4 md:gap-7">
                  <div className="w-full">
                    <p>Enter Your Name</p>
                    <input
                      type="text"
                      placeholder="Full Name"
                      className="p-5 bg-transparent border border-troo-hrtag rounded w-full"
                    />
                  </div>
                  <div>
                    <p>Enter Your Phone</p>
                    <input
                      type="number"
                      placeholder="Phone"
                      className="p-5 bg-transparent border rounded border-troo-hrtag w-full"
                    />
                  </div>
                  <div>
                  <p>Enter Your Email</p>
                    <input
                      type="email"
                      placeholder="Email"
                      className="p-5 bg-transparent border rounded border-troo-hrtag w-full"
                    />
                  </div>
                  <div>
                  <p>Subject</p>
                    <input
                      type="text"
                      placeholder="Subject"
                      className="p-5 bg-transparent border rounded border-troo-hrtag w-full"
                    />
                  </div>
                </div>
                <div className="w-full">
                <p>Message</p>
                  <textarea
                    placeholder="White something to me"
                    className="p-5 bg-transparent border rounded border-troo-hrtag w-full"
                    rows={8}
                  />
                </div>
                <div>
                  <Link className="btn4"> Send Message</Link>
                </div>
              </div>
            </form>
      </div>
    </div>
  );
};

export default BlogDetails;
