import React from "react";
import { GalleryData } from "../Data/GalleryData";
import { Link } from "react-router-dom";
import { ImArrowUpRight2 } from "react-icons/im";
import BrandLogo from "./BrandLogo";

const Gallerys = () => {
  return (
    <div className="gallery py-12 lg:py-24">
      <div className="main_container">
        <div className="text-center">
          <p className="bg-troo-headingbg py-2 px-6 rounded-full mb-5  inline-block">
          Lets See Our Gallery
          </p>
          <h2 className="mb-5 max-w-[400px] m-auto">
          Lets See 

            <span className="text-troo-secondary"> Recent Tour </span>Photos and Video
          </h2>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mb-5">
        {GalleryData.slice(0,3).map((e, i) => (
            <Link key={i} className="relative overflow-hidden w-full group after:content-[''] hover:after:bg-troo-bluefade2 after:w-full after:absolute after:top-0 after:left-0 after:h-full after:z-10 before:bg-opacity-0 group-hover:before:bg-opacity-10">
              <img className="w-full h-[350px]" src={e.img} alt={e.img} />
              <div className="absolute left-5 w-[90%] bottom-5 flex justify-between items-center bg-troo-white z-20  opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <div className="ps-8">
                  <h4>Beauty of India</h4>
                  <p>25 Trips</p>
                </div>
                <div className="px-6 py-9 bg-troo-secondary">
                  <ImArrowUpRight2 className="text-troo-primary" />
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-8">
        {GalleryData.slice(3,5).map((e, i) => (
              <Link key={i} className="relative overflow-hidden w-full group after:content-[''] hover:after:bg-troo-bluefade2 after:w-full after:absolute after:top-0 after:left-0 after:h-full after:z-10 before:bg-opacity-0 group-hover:before:bg-opacity-10">
              <img className="w-full h-[350px]" src={e.img} alt={e.img} />
              <div className="absolute left-5 w-[90%] bottom-5 flex justify-between items-center bg-troo-white z-20  opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <div className="ps-8">
                  <h4>Beauty of India</h4>
                  <p>25 Trips</p>
                </div>
                <div className="px-6 py-9 bg-troo-secondary">
                  <ImArrowUpRight2 className="text-troo-primary" />
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="text-center galleryClick">
            <Link>Want to See More Photos? <span className="text-troo-secondary"> Click Here</span></Link>
        </div>
        <BrandLogo/>
      </div>
    </div>
  );
};

export default Gallerys;
