import React from "react";
import Layout from "../../Components/Layout";
import BannerGlobal from "../../Components/BannerGlobal";
import Destination2 from "../../Components/Destination2";
import useDocumentTitle from "../../Hooks/PageTitle";


const DestinationPage = () => {
  useDocumentTitle("TRoo Traveling | Destination")
  return (
    <div>
      <Layout>
        <BannerGlobal />
       
        <Destination2 />
      </Layout>
    </div>
  );
};

export default DestinationPage;
