import React from "react";
import { Link, createSearchParams } from "react-router-dom";
import { TourData } from "../Data/TourData";

const TourPackage = () => {
  return (
    <div className="tourPackage bg-troo-bluefade py-12 lg:py-24">
      <div className="main_container">
        <p className="bg-troo-headingbg py-2 px-6 rounded-full mb-5  inline-block">
        Our Popular Tour Packages
        </p>
        <div className="flex flex-col lg:flex-row w-full justify-between gap-6 mb-12">
          <h2 className="w-full">
            Awesome Tour
            <span className="text-troo-secondary">Package for</span> Your F
            amily Member
          </h2>
          <p className="flex-grow w-full">
            Contrary to popular belief, Lorem Ipsum is not simply random
            text.has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old.
          </p>
          <div className="w-full lg:flex flex-col items-end ">
            <Link to="/tour-package" className="btn4 ">View All Packages</Link>
          </div>
        </div>
        <div className="grid gap-6 grid-cols-1 md:grid-cols-2  lg:grid-cols-3">
          {TourData.slice(0, 6).map((e, i) => (
            <Link
              to={`/tour-package/tour-package-details?${createSearchParams({
                id: e.id,
              })}`}
              key={i}
              className="relative overflow-hidden w-full group after:content-[''] hover:after:bg-troo-bluefade2 after:w-full after:absolute after:top-0 after:left-0 after:h-full after:z-10 before:bg-opacity-0 group-hover:before:bg-opacity-10"
            >
              <img className="w-full" src={e.img} alt={e.img} />
              <div className="absolute left-5 rounded right-5 bottom-3  bg-troo-white w-[90%] z-20  opacity-0 group-hover:opacity-100 transition-opacity duration-300 ">
                <div className="px-7 py-4 border-b-2 border-troo-bluefade">
                  <div className="flex justify-between mb-3">
                    <h4>{e.name}</h4>
                    <div className="flex items-center gap-2">
                      {e.star}
                      {e.rating}
                    </div>
                  </div>
                  <div className="flex items-center gap-3 mb-2">
                    {e.location}
                    {e.locationName}
                  </div>
                  <div className="flex items-center gap-3 mb-2">
                    {e.coin}
                    {e.price}
                    {e.disCount}
                  </div>
                  <div className="flex items-center gap-3 mb-2">
                    {e.clock}
                    {e.day}
                  </div>
                </div>
                <div className="py-5 text-center">
                  <h5>View Package Detail</h5>
                </div>
              </div>
              <div className="absolute left-5 rounded right-5 bottom-3  bg-troo-white w-[90%]">
                <div className="py-5 text-center">
                  <h5>View Package Detail</h5>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TourPackage;
