import React from "react";
import { BrandData } from "../Data/BrandData";

const BrandLogo = () => {
  return (
    <div className="brand pt-12">
      <div className="grid grid-cols-2 md:grid-cols-4 xl:flex flex-wrap justify-between gap-5 xl:gap-0">
        {BrandData.map((e, i) => (
          <div className="bg-troo-white p-5 w-full xl:max-w-[207px]" key={i}>
            <img className="w-full" src={e.img} alt={e.img} />
          </div>
        ))}
      </div>
      <div className="2xl:flex w-full xl:justify-center mt-5">
        <div className="grid grid-cols-2 md:grid-cols-4 xl:flex flex-wrap justify-between gap-5 xl:gap-0 w-full  xl:w-[84%]">
          {BrandData.slice(0,5).map((e, i) => (
            <div className="bg-troo-white p-5  w-full xl:max-w-[207px]" key={i}>
              <img className="w-full" src={e.img} alt={e.img} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BrandLogo;
