export const FaqData = [
    {
        id : 1,
        name : "What are the supported modes of payment you accept?",
        para :"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's dummy text of the printing and typesetting standard dummy text ever since."
    },
    {
        id : 2,
        name : "What is your cancellation policy?",
        para :"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's dummy text of the printing and typesetting standard dummy text ever since."
    },
    {
        id : 3,
        name : "What if I face any issues during the Tour?",
        para :"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's dummy text of the printing and typesetting standard dummy text ever since."
    },
    {
        id : 4,
        name : "How do I sign up for a tour and how much is the deposit?",
        para :"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's dummy text of the printing and typesetting standard dummy text ever since."
    },
    {
        id : 5,
        name : "How do I apply a credit to my tour?",
        para :"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's dummy text of the printing and typesetting standard dummy text ever since."
    },
    {
        id : 6,
        name : "What is your cancellation policy?",
        para :"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's dummy text of the printing and typesetting standard dummy text ever since."
    },
    {
        id : 7,
        name : "Will I need COVID-19 vaccination or any other immunizations?",
        para :"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's dummy text of the printing and typesetting standard dummy text ever since."
    },
    {
        id : 8,
        name : "How do I make pre- or post-tour hotel reservations?",
        para :"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's dummy text of the printing and typesetting standard dummy text ever since."
    },
    {
        id : 9,
        name : "When will I receive the list of hotels for my tour?",
        para :"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's dummy text of the printing and typesetting standard dummy text ever since."
    },
    {
        id : 10,
        name : "How do I know if there are enough seats left on a tour?",
        para :"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's dummy text of the printing and typesetting standard dummy text ever since."
    },
    {
        id : 11,
        name : "Are there any age restrictions for tour members?",
        para :"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's dummy text of the printing and typesetting standard dummy text ever since."
    },
    {
        id : 12,
        name : "How will I receive a refund?",
        para :"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's dummy text of the printing and typesetting standard dummy text ever since."
    },
]