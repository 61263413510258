import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import Blogs2 from '../../Components/Blogs2'
import useDocumentTitle from '../../Hooks/PageTitle'

const BlogPage = () => {
  useDocumentTitle("TRoo Traveling | Blogs Page")
  return (
    <div>
        <Layout>
            <BannerGlobal/>
            <Blogs2/>
        </Layout>
    </div>
  )
}

export default BlogPage