import React from "react";
import { DestinationData } from "../Data/DestinationData";
import { Link, createSearchParams } from "react-router-dom";
import { ImArrowUpRight2 } from "react-icons/im";

const Destination2 = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mb-10">
          {DestinationData.map((e, i) => (
            <Link
              to={`/destinations/destinations-details?${createSearchParams({
                id: e.id,
              })}`}
              key={i}
              className="relative overflow-hidden w-full group after:content-[''] hover:after:bg-troo-bluefade2 after:w-full after:absolute after:top-0 after:left-0 after:h-full after:z-10 before:bg-opacity-0 group-hover:before:bg-opacity-10"
            >
              <img className="w-full h-full lg:h-[400px] " src={e.img} alt={e.img} />
              <div className="absolute left-5 w-[90%] bottom-5 flex justify-between items-center bg-troo-white z-20  opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <div className="ps-8">
                  <h4>{e.name}</h4>
                  <p>{e.para}</p>
                </div>
                <div className="px-6 py-9 bg-troo-secondary">
                  <ImArrowUpRight2 className="text-troo-primary" />
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Destination2;
