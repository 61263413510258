import React from 'react'
import Error404 from '../../Components/Error404'
import useDocumentTitle from '../../Hooks/PageTitle'

const ErrorPage = () => {
  useDocumentTitle("TRoo Traveling | Error Page")
  return (
    <div>
        <Error404/>
    </div>
  )
}

export default ErrorPage