import React, { useState } from "react";
import happy from "../images/happy.png";
import thumg from "../images/thumb.png";
import location from "../images/location.png";
import medal from "../images/medal.png";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const Counters = () => {
  const [counter, setCounter] = useState(false);
  return (
    <div className="counter bg-troo-bluefade pb-24">
      <div className="main_container">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8">
          <div className="bg-troo-white flex flex-col items-center gap-2 py-5 group rounded border border-white hover:border-troo-secondary">
            <img src={happy} alt="happy" />
            <div className="text-[64px] group-hover:text-troo-secondary font-Archivo font-bold">
              <ScrollTrigger
                onEnter={() => setCounter(true)}
                onExit={() => setCounter(false)}
              >
                {counter && (
                  <CountUp start={50} end={250} duration={3} delay={0} />
                )}
                <strong>k</strong>
              </ScrollTrigger>
            </div>
            <h3>Happy Traveler</h3>
          </div>
          <div className="bg-troo-white flex flex-col items-center gap-2 py-5 group rounded border border-white hover:border-troo-secondary">
            <img src={location} alt="location" />
            <div className="text-[64px] group-hover:text-troo-secondary font-Archivo font-bold">
            <ScrollTrigger
                onEnter={() => setCounter(true)}
                onExit={() => setCounter(false)}
              >
                {counter && (
                  <CountUp start={10} end={90} duration={3} delay={0} />
                )}
                <strong>+</strong>
              </ScrollTrigger>
            </div>
            <h3>World Wild Place</h3>
          </div>
          <div className="bg-troo-white flex flex-col items-center gap-2 py-5 group rounded border border-white hover:border-troo-secondary">
            <img src={thumg} alt="thumg" />
            <div className="text-[64px] group-hover:text-troo-secondary font-Archivo font-bold">
            <ScrollTrigger
                onEnter={() => setCounter(true)}
                onExit={() => setCounter(false)}
              >
                {counter && (
                  <CountUp start={10} end={75} duration={3} delay={0} />
                )}
                <strong>%</strong>
              </ScrollTrigger>
            </div>
            <h3>Positive Review</h3>
          </div>
          <div className="bg-troo-white flex flex-col items-center gap-2 py-5 group rounded border border-white hover:border-troo-secondary">
            <img src={medal} alt="medal" />
            <div className="text-[64px] group-hover:text-troo-secondary font-Archivo font-bold">
            <ScrollTrigger
                onEnter={() => setCounter(true)}
                onExit={() => setCounter(false)}
              >
                {counter && (
                  <CountUp start={20} end={45} duration={3} delay={0} />
                )}
                <strong>+</strong>
              </ScrollTrigger>
            </div>
            <h3>Award Wining</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Counters;
