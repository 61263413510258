import React from "react";
import { Link } from "react-router-dom";

const Highlight2 = () => {
  return (
    <div className="Highlight">
      <div className="main_container Highlightbg mt-[-80px] relative z-10 py-10 ">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="text-troo-white ps-5">
            <h3 className="mb-3 relative z-[1] before:content-[''] before:bg-yellow-circle before:absolute before:left-[-25px] before:top-[-30px] before:w-full before:h-[400%] before:bg-no-repeat before:z-[-1] ">
              Are You Ready to Start n             
              <span className="text-troo-primary">Exploring</span> The World?
            </h3>
            <p className="max-w-[500px]">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
          </div>
          <div className="flex justify-end items-center">
            <Link
              to="/contact-us"
              className="btn4 relative z-[1] before:content-[''] before:bg-blue-circle before:absolute before:left-[-25px] before:top-[-25px] before:w-full before:h-full before:bg-no-repeat before:z-[-1]"
            >
              Contact With Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Highlight2;
