import React from "react";
import { ContactBoxData } from "../Data/ContactBoxData";
import { Link } from "react-router-dom";

const Contacts = () => {
  return (
    <div className="pt-12 lg:pt-24 ">
      <div className="main_container">
        <div className="pb-24">
          <div className="flex flex-wrap justify-center gap-6">
            {ContactBoxData.map((e, i) => (
              <div className="flex flex-col items-center w-[320px] gap-5 p-6 border border-t-troo-bluefade shadow">
                <div className="p-2 bg-troo-primary rounded">{e.icon}</div>
                <h3>{e.name}</h3>
                <p className="text-center">
                  {e.para1}
                  <br />
                  {e.para2}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-center mb-[-100px] relative z-100 ">
          <div className="lg:w-[90%] p-4 sm:p-8 shadow-contact rounded bg-troo-white">
            <h2 className="text-troo-secondary">Estimate your Project?</h2>
            <form className="mt-12">
              <div className="flex flex-col gap-y-8">
                <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-4 md:gap-7">
                  <div className="w-full">
                    <p>Enter Your Name</p>
                    <input
                      type="text"
                      placeholder="Full Name"
                      className="p-2 sm:p-5 bg-transparent border border-troo-hrtag rounded w-full"
                    />
                  </div>
                  <div>
                    <p>Enter Your Phone</p>
                    <input
                      type="number"
                      placeholder="Phone"
                      className="p-2 sm:p-5 bg-transparent border rounded border-troo-hrtag w-full"
                    />
                  </div>
                  <div>
                  <p>Enter Your Email</p>
                    <input
                      type="email"
                      placeholder="Email"
                      className="p-2 sm:p-5 bg-transparent border rounded border-troo-hrtag w-full"
                    />
                  </div>
                  <div>
                  <p>Subject</p>
                    <input
                      type="text"
                      placeholder="Subject"
                      className="p-2 sm:p-5 bg-transparent border rounded border-troo-hrtag w-full"
                    />
                  </div>
                </div>
                <div className="w-full">
                <p>Message</p>
                  <textarea
                    placeholder="White something to me"
                    className="p-2 sm:p-5 bg-transparent border rounded border-troo-hrtag w-full"
                    rows={8}
                  />
                </div>
                <div>
                  <Link className="btn4"> Send Message</Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div >
      <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7343.710423642854!2d72.49508733426413!3d23.029087824997855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b278e046fa5%3A0xd837def63555f791!2sTRooInbound%20Private%20Limited!5e0!3m2!1sen!2sin!4v1687782488933!5m2!1sen!2sin"
          width="100%"
          height="600"
          style={{ border: "0" }}
          allowfullscreen=""
          loading="lazy"
          title="gmap"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Contacts;
