import React, { useEffect, useState } from "react";
import planeleft from "../images/Planeleft2.png";
import planeRight from "../images/PlaneRight2.png";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { DestinationData } from "../Data/DestinationData";
import { BlogData } from "../Data/BlogData";
import { TeamData } from "../Data/TeamData";
import { HeaderData } from "../Data/HeaderData";
import { TourData } from "../Data/TourData";

const BannerGlobal = () => {
  const [heading, setHeading] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  let pathName = location.pathname;

useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      if (pathName.includes("destinations-details")) {
        const dData = DestinationData.find((e) => e.id == id);
        setHeading(dData.name);
      }
      if (pathName.includes("blog-details")) {
        const bData = BlogData.find((e) => e.id == id);
        setHeading(bData.name);
      }
    
      if (pathName.includes("team-details")) {
        const tData = TeamData.find((e) => e.id == id);
        setHeading(tData.name);
      }
      if (pathName.includes("tour-package-details")) {
        const tourData = TourData.find((e) => e.id == id);
        setHeading(tourData.name);
      }
    } else {
      const data = HeaderData.find((data) => {
        if (data?.subNav) {
          const subTitel = data?.subNav?.find((s) => s.path === pathName);
          if (subTitel) {
            return data;
          }
        } else if (data.path === pathName) {
          return data;
        }
      });

      if (data?.subNav) {
        const obj = data?.subNav.find((s) => s.path === pathName);
        setHeading(obj.heading);
      } else {
        setHeading(data.heading);
      }
    }
  }, []);
  useEffect(() => {
    const pathnamee = location.pathname.split("/").filter((p) => !!p);
    const breadcrumbs = pathnamee.map((e, i) => ({
      title: e,
      path: `/${pathnamee.slice(0, i + 1).join("/")}`,
    }));
    setBreadcrumbs(breadcrumbs);
  }, [location]);
  return (
    <div className="top-0 w-full">
      <div className="BannerGlogal w-full py-12 lg:py-24 relative">
        <div className="planLeft">
          <img src={planeleft} alt="planeleft" />
        </div>
        <div className="planeRight">
          <img src={planeRight} alt="planeRight" />
        </div>
        <div className="main_container py-12 lg:py-24">
          <div className="pt-24">
            <h2 className="text-troo-white text-center">{heading}</h2>
          </div>
        </div>
        <div className="absolute bottom-[-20px] flex justify-center  w-full  ">
            <div className="bg-troo-white inline-block rounded shadow-md">
                <ul className="flex flex-wrap gap-1 justify-center  px-5 py-3 ">
                    <Link to="/" className="text-troo-deepblack">
                    Home
                    </Link>
                    <span>&gt;</span>
                    {breadcrumbs.map((breadcrumb, index) => (
                    <>
                        <li
                        className={` capitalize text-troo-deepblack ${
                            index === breadcrumbs.length - 1 ? " text-troo-secondary" : ""
                        } ${breadcrumb.path === "/faq" && "uppercase"}`}
                        >
                        <Link key={index} to={breadcrumb.path}>
                            {breadcrumb.title.replace(/-/g, " ")}
                        </Link>
                        </li>
                        {index !== breadcrumbs?.length - 1 && <span>&gt;</span>}
                    </>
                    ))}
                </ul>
            </div>
        </div>
      </div>
    </div>
  );
};

export default BannerGlobal;
