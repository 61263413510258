import React from 'react'
import { FaqData } from '../Data/FAQData';
import Accordian from './Accordian';

const FAQs2 = () => {
  return (
    <div className="py-12 lg:py-24">
        <div className="main_container">
        <div className="lg:flex gap-6 py-10">
            <div className="flex flex-col gap-4 items-start">
              {FaqData.slice(0, 12).map((data, i) => {
                return <Accordian key={i} title={data.name} desc={data.para} />;
              })}
            </div>
            <div className="flex flex-col gap-4 items-start">
              {FaqData.slice(0, 12).map((data, i) => {
                return <Accordian key={i} title={data.name} desc={data.para} />;
              })}
            </div>
          </div>
        </div>
    </div>
  )
}

export default FAQs2