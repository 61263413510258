import React from "react";
import { Link, createSearchParams } from "react-router-dom";
import { BlogData } from "../Data/BlogData";
import { BsArrowRight } from "react-icons/bs";

const Blogs = () => {
  return (
    <div className="blog py-12 lg:py-24">
      <div className="main_container">
        <p className="bg-troo-headingbg py-2 px-6 rounded-full mb-5  inline-block">
          Meet Your Tour Guide
        </p>
        <div className="flex flex-col lg:flex-row w-full justify-between gap-6 mb-12">
          <h2 className="w-full">
            Amazing News &<span className="text-troo-secondary"> Blog for </span>
            Every Single Update
          </h2>
          <p className="flex-grow w-full">
            Contrary to popular belief, Lorem Ipsum is not simply random
            text.has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old.
          </p>
          <div className="w-full lg:flex flex-col items-end ">
            <Link to="/our-blogs" className="btn4 ">Read All Blogs</Link>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          <div className="border border-troo-bluefade rounded">
            {BlogData.slice(0, 1).map((e, i) => (
              <Link key={i} to={`/our-blogs/blog-details?${createSearchParams({id : e.id})}`}>
                <div className="relative">
                  <img className="w-full" src={e.img} alt={e.img} />
                  <div className="absolute bottom-5 left-5 bg-troo-primary p-3 w-20 rounded text-center">
                    <p className="text-troo-white">03 March 2023</p>
                  </div>
                </div>
                <div className="p-5">
                  <p className="mb-5">{e.date}</p>
                  <h3 className="mb-5">{e.name}</h3>
                  <p className="text-troo-secondary flex items-center gap-2">
                    Read Full Article <BsArrowRight />
                  </p>
                </div>
              </Link>
            ))}
          </div>
          <div className="flex flex-col gap-5">
            {BlogData.slice(1, 3).map((e, i) => (
              <Link  to={`/our-blogs/blog-details?${createSearchParams({id : e.id})}`} className="grid grid-cols-1 lg:grid-cols-2 border border-troo-bluefade rounded" key={i}>
                <div>
                  <img className="w-full" src={e.img} alt={e.img} />
                </div>
                <div className="px-3 py-5 flex flex-col gap-5">
                  <h6>{e.date}</h6>
                  <h4>{e.name}</h4>
                  <p>{e.para}</p>
                  <div className="h-[1px]  bg-troo-bluefade"></div>
                  <p>{e.sub}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
