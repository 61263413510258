import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import TourPackage2 from '../../Components/TourPackage2'
import useDocumentTitle from '../../Hooks/PageTitle'

const TourPackagePage = () => {
  useDocumentTitle("TRoo Traveling | Tour Packages")
  return (
    <div>
        <Layout>
            <BannerGlobal/>
            <TourPackage2/>
        </Layout>
    </div>
  )
}

export default TourPackagePage