import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import TeamDetails from '../../Components/TeamDetails'
import Feedback from '../../Components/Feedback'
import useDocumentTitle from '../../Hooks/PageTitle'

const TeamDetailsPage = () => {
  useDocumentTitle("TRoo Traveling | Team Details Page")
  return (
    <div className='teamDetailsPage'>
        <Layout>
            <BannerGlobal/>
            <TeamDetails/>
            <Feedback/>
        </Layout>
    </div>
  )
}

export default TeamDetailsPage