import gallery1 from "../images/gallery1.png";
import gallery2 from "../images/gallery2.png";
import gallery3 from "../images/gallery3.png";
import gallery4 from "../images/gallery4.png";
import gallery5 from "../images/gallery5.png";
import gallery6 from "../images/gallery6.png";
import gallery7 from "../images/gallery7.png";
import gallery8 from "../images/gallery8.png";
import gallery9 from "../images/gallery9.png";
import gallery10 from "../images/gallery10.png";
import gallery11 from "../images/gallery11.png";

export const GalleryData = [
  {
    id: 1,
    img: gallery1,
    name: "Machu Picchu",
    para: "Peru",
  },
  {
    id: 2,
    img: gallery2,
    name: "Gir",
    para: "Gujarat",
  },
  {
    id: 3,
    img: gallery3,
    name: "Lonavala",
    para: "Pune",
  },

  {
    id: 4,
    img: gallery4,
    name: "KumbhalGhad",
    para: "Rajestan",
  },
  {
    id: 5,
    img: gallery5,
    name: "Chillika",
    para: "Odisha",
  },
  {
    id: 6,
    img: gallery6,
    name: "Chillika",
    para: "Odisha",
  },
  {
    id: 7,
    img: gallery7,
    name: "Chillika",
    para: "Odisha",
  },
  {
    id: 8,
    img: gallery8,
    name: "Chillika",
    para: "Odisha",
  },
  {
    id: 9,
    img: gallery9,
    name: "Chillika",
    para: "Odisha",
  },
  {
    id: 10,
    img: gallery10,
    name: "Chillika",
    para: "Odisha",
  },
  {
    id: 11,
    img: gallery11,
    name: "Chillika",
    para: "Odisha",
  },

];
