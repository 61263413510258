import React from "react";
import logo from "../images/Logo2.svg";
import { Link } from "react-router-dom";
import commingRight from "../images/comingSoonImg.png"

const CommingSoon = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2">
      <div className="comingsoon px-5 md:px-12 lg:px-36 py-8 md:py-16 lg:py-44 flex flex-col gap-6">
        <img className="w-[230px]" src={logo} alt="logo" />
        <div>
          <p className="inline-block bg-troo-secondary py-2 px-5 text-troo-white rounded">
            Coming Soon
          </p>
        </div>
        <h2 className="text-troo-secondary">Our New Website Is on Its Way.</h2>
        <p className="mb-2">
          Contrary to popular belief, Lorem Ipsum is not simply random text. It
          has roots in a piece of classical Latin literature from 45 BC Lorem
          Ipsum.
        </p>
        <h5>Notify Me </h5>
        <div className="flex flex-wrap relative w-full items-stretch mb-14 ">
          <input
            className="w-full py-[13px] rounded px-5 block"
            type="email"
            placeholder="Enter your Email"
          />
          <Link className="btn4 absolute right-0">Submit</Link>
        </div>
        <div className="flex items-center gap-3">
          <h3>Contact Us</h3>
          <div className="h-1 w-12 bg-troo-deepblack"></div>
        </div>
        <h3>+44 987 654 3210</h3>
        <h3>contact@troothems.com</h3>
      </div>
      <div className="comingsoonright">
        <img className="w-100% lg:hidden" src={commingRight} alt="commingRight" />
      </div>
    </div>
  );
};

export default CommingSoon;
