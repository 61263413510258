import React from "react";
import { TravelData } from "../Data/TravelCatData";
import { Link } from "react-router-dom";
import { ImArrowUpRight2 } from "react-icons/im";
import cat8 from "../images/cat8.png";

const TravelCategory = () => {
  return (
    <div className="bg-travel-cat-bg bg-no-repeat w-full bg-bottom">
      <div className="main_container py-12 lg:py-24 mt-[-120px] relative z-10 bg-troo-white rounded">
        <div className="text-center">
          <p className="bg-troo-headingbg py-2 px-6 rounded-full mb-5  inline-block">
            Our Travel Categories
          </p>
          <h2 className="mb-5 max-w-[500px] m-auto">
            Here's Our Most Popular
            <span className="text-troo-secondary"> Travel Categories</span>
          </h2>
        </div>
        <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 mt-10">
          {TravelData.map((e, i) => (
            <Link key={i}className="relative overflow-hidden w-full group after:content-[''] hover:after:bg-troo-bluefade2 after:w-full after:absolute after:top-0 after:left-0 after:h-full after:z-10 before:bg-opacity-0 group-hover:before:bg-opacity-10">
              <img className="w-full" src={e.img} alt={e.img} />
              <div className="absolute left-5 w-[90%] bottom-5 flex justify-between items-center bg-troo-white z-20 opacity-0 group-hover:opacity-100 duration-500 transition-all scale-0 group-hover:scale-100">
                <div className="ps-5">
                  <h4>{e.name}</h4>
                </div>
                <div className="px-6 py-6 bg-troo-secondary">
                  <ImArrowUpRight2 className="text-troo-primary" />
                </div>
              </div>
            </Link>
          ))}
          <Link className="relative w-full h-full flex justify-center items-center">
            <img className="w-full" src={cat8} alt={cat8} />
            <div className="absolute w-[93%] h-[90%] bg-troo-white p-5 flex flex-col gap-5 items-center justify-center">
              <h4 className="text-center relative h-[70px] w-full after::content-[''] after:bg-arrowCard-bg after:absolute after:w-full after:h-full after:left-0 after:bg-no-repeat ">We Have More 5 Categories</h4>
              <Link to="/destinations" className="btn4">View All Categories</Link>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TravelCategory;
