import {BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./Routes/Home";
import AboutPage from "./Routes/AboutPage";
import DestinationPage from "./Routes/Destnation/DestinationPage";
import DestinationDetailsPage from "./Routes/Destnation/DestinationDetailsPage";
import TourPackagePage from "./Routes/Pages/TourPackagePage";
import TourPackageDetailsPage from "./Routes/Pages/TourPackageDetailsPage";
import TestimonialPage from "./Routes/Pages/TestimonialPage";
import TeamPage from "./Routes/Pages/TeamPage";
import TeamDetailsPage from "./Routes/Pages/TeamDetailsPage";
import { FAQPage } from "./Routes/Pages/FAQPage";
import ErrorPage from "./Routes/Pages/ErrorPage";
import ComingSoonPage from "./Routes/Pages/ComingSoonPage";
import BlogPage from "./Routes/Blog/BlogPage";
import BlogDetailsPage from "./Routes/Blog/BlogDetailsPage";
import ContactPage from "./Routes/ContactPage";
import TourPlanPage from "./Routes/TourPlanPage";

function App() {
  return (
    <div className="App">
     <BrowserRouter>
     <Routes>
      <Route path="/" element={<Home/>}  />
      <Route path="/about-me" element={<AboutPage/>}  />
      <Route path="/destinations" element={<DestinationPage/>}  />
      <Route path="/destinations/destinations-details" element={<DestinationDetailsPage/>}  />
      <Route path="/tour-package" element={<TourPackagePage/>}  />
      <Route path="/tour-package/tour-package-details" element={<TourPackageDetailsPage/>}  />
      <Route path="/testimonial" element={<TestimonialPage/>}  />
      <Route path="/our-team" element={<TeamPage/>}  />
      <Route path="/our-team/our-team-details" element={<TeamDetailsPage/>}  />
      <Route path="/faq" element={<FAQPage/>}  />
      <Route path="/error-404" element={<ErrorPage/>}  />
      <Route path="/coming-soon" element={<ComingSoonPage/>}  />
      <Route path="/our-blogs" element={<BlogPage/>}  />
      <Route path="/our-blogs/blog-details" element={<BlogDetailsPage/>}  />
      <Route path="/contact-us" element={<ContactPage/>}  />
      <Route path="/plan-my-tour" element={<TourPlanPage/>}  />

     </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;
