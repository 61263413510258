import blog1 from "../images/blog1.png"
import blog2 from "../images/blog2.png"
import blog3 from "../images/blog3.png"
import blog4 from "../images/blog4.png"
import blog5 from "../images/blog5.png"
import blog6 from "../images/blog6.png"
import blog7 from "../images/blog7.png"
import blog8 from "../images/blog8.png"
import blog9 from "../images/blog9.png"
import blog10 from "../images/blog10.png"
import blog11 from "../images/blog11.png"


export const BlogData = [
    {
        id :1,
        img : blog1,
        name : "Itinerary & DIY Travel Guide for First-Time Visitors: Trip Planning for 1 to 5 Days or More",
        sub : "Admin / Travel, Adventure",
        date :"03 March 2023"
    },
    {
        id :2,
        img : blog2,
        name : "Our 16 Favourite Beaches in Puglia",
        sub : "Admin / Travel, Adventure",
        date :"28 February, 2023",
        para : "There are many variations of passages Lorem Ipsum available."
    },
    {
        id :3,
        img : blog3,
        name : "12 Best Things To Do In Annecy, France",
        sub : "Admin / Travel, Adventure",
        date :"28 February, 2023",
        para : "There are many variations of passages Lorem Ipsum available."
    },
    {
        id :4,
        img : blog4,
        name : "10 Products You Need To Pack For Your Luxury Summer Travel",
        sub : "Admin / Travel, Adventure",
        date :"28 February, 2023",
        para : "There are many variations of passages Lorem Ipsum available."
    },
    {
        id :5,
        img : blog5,
        name : "Our Top 10 Luxury Hotels For Stay While Traveling In Europe",
        sub : "Admin / Travel, Adventure",
        date :"28 February, 2023",
        para : "There are many variations of passages Lorem Ipsum available."
    },
    {
        id :6,
        img : blog6,
        name : "Explore The Wild & Wonderful national Parks With Experts",
        sub : "Admin / Travel, Adventure",
        date :"28 February, 2023",
        para : "There are many variations of passages Lorem Ipsum available."
    },
    {
        id :7,
        img : blog7,
        name : "20 Best Things To Do In Delhi, India",
        sub : "Admin / Travel, Adventure",
        date :"28 February, 2023",
        para : "There are many variations of passages Lorem Ipsum available."
    },

    {
        id :8,
        img : blog8,
        name : "Reasons Of Why Autumn Is The Perfect Time For Touring",
        sub : "Admin / Travel, Adventure",
        date :"28 February, 2023",
        para : "There are many variations of passages Lorem Ipsum available."
    },
    {
        id :9,
        img : blog9,
        name : "Hawaii Beaches, Sight-Seen & Historical Landmarks",
        sub : "Admin / Travel, Adventure",
        date :"28 February, 2023",
        para : "There are many variations of passages Lorem Ipsum available."
    },
    {
        id :10,
        img : blog10,
        name : "Khunjerab: The Highest Border Between Pakistan & China",
        sub : "Admin / Travel, Adventure",
        date :"28 February, 2023",
        para : "There are many variations of passages Lorem Ipsum available."
    },
    {
        id :11,
        img : blog11,
        name : "10 Best Things To Do In Colambo, Shrilanka",
        sub : "Admin / Travel, Adventure",
        date :"28 February, 2023",
        para : "There are many variations of passages Lorem Ipsum available."
    },
   
]