import React, { useEffect, useState } from "react";
import DetailsImg from "../images/destinationDetails.png";
import { TbDiscountCheck } from "react-icons/tb";
import { GrLocation } from "react-icons/gr";
import { GalleryData } from "../Data/GalleryData";
import { Link, useSearchParams } from "react-router-dom";
import { DestinationData } from "../Data/DestinationData";

const DestinationDetails = () => {
  const [heading, setHeading] = useState("")
  const [picture, setPicture] = useState("")
  const [scarchparam] =useSearchParams()
  const id = scarchparam.get("id")

  useEffect(()=>{
    const filData = DestinationData.find((data)=>data?.id == id)
    setHeading(filData?.name)
    setPicture(filData?.img)
  },[id])
  return (
    <div className=" w-full py-12 lg:py-24 ">
      <div className="main_container">
        <div className="flex flex-col lg:flex-row h-full items-start gap-6">
          <div className="w-full lg:w-[75%] flex flex-col items-start gap-5">
            <img className="w-full max-h-[550px]" src={picture || DetailsImg} alt="DetailsImg" />
            <h2>{heading || "Something About India"}</h2>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary, making
              this the first true generator on the Internet. It uses a
              dictionary of over 200 Latin words, combined with a handful of
              model sentence structures, to generate Lorem Ipsum which looks
              reasonable. The generated Lorem Ipsum is therefore always free
              from repetition, injected humour, or non-characteristic words etc.
            </p>
            <h3>Information</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:flex justify-between gap-5 w-full p-5 border border-troo-bluefade rounded shadow-sm">
              <diiv className="flex flex-col gap-2">
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  Tour Type:
                  <span className="font-semibold"> Family Tour </span>
                </p>
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  Price:
                  <span className="font-semibold"> $400.00 - $500.00 </span>
                </p>
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  Tour Type:
                  <span className="font-semibold"> Family Tour </span>
                </p>
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  Capital:
                  <span className="font-semibold"> New Delhi </span>
                </p>
              </diiv>
              <div className="flex flex-col gap-2">
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  Language:
                  <span className="font-semibold"> Hindi and English </span>
                </p>
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  Currency Used:
                  <span className="font-semibold"> Indian Rupee </span>
                </p>
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  We Can Visit:
                  <span className="font-semibold"> More Then 25+ Places </span>
                </p>
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  CDrives on the:
                  <span className="font-semibold"> Left Side </span>
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  Visa Requirement:
                  <span className="font-semibold"> Yes </span>
                </p>
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  Area (2km):
                  <span className="font-semibold"> 3.287 million km² </span>
                </p>
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  Dress Code:
                  <span className="font-semibold"> comfortable & light </span>
                </p>
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  Time Zone:
                  <span className="font-semibold"> GMT+5:30 </span>
                </p>
              </div>
            </div>
            <h3>Price Includes</h3>
            <div className="sm:flex justify-between gap-5 w-full p-5 border border-troo-bluefade rounded shadow-sm">
              <div className="flex flex-col gap-2">
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  Accommondatio
                </p>
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  Breakfast
                </p>
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  Flights
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  Porter & Personal Guide
                </p>
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  Tranportation / Car
                </p>
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  Typical Souvenir
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  Insurance
                </p>
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  Lunch
                </p>
              </div>
            </div>
            <h3>Itinerary</h3>
            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
              <div className="p-5 border border-troo-bluefade shadow-sm rounded">
                <h5 className="flex items-center gap-2 mb-4">
                  <GrLocation /> Location 1
                </h5>
                <p>
                  There are many Locations of Lorem passages of Lorem Ipsum
                  available, but the majority.
                </p>
              </div>
              <div className="p-5 border border-troo-bluefade shadow-sm rounded">
                <h5 className="flex items-center gap-2 mb-4">
                  <GrLocation /> Location 2
                </h5>
                <p>
                  There are many Locations of Lorem passages of Lorem Ipsum
                  available, but the majority.
                </p>
              </div>
              <div className="p-5 border border-troo-bluefade shadow-sm rounded">
                <h5 className="flex items-center gap-2 mb-4">
                  <GrLocation /> Location 3
                </h5>
                <p>
                  There are many Locations of Lorem passages of Lorem Ipsum
                  available, but the majority.
                </p>
              </div>
              <div className="p-5 border border-troo-bluefade shadow-sm rounded">
                <h5 className="flex items-center gap-2 mb-4">
                  <GrLocation /> Location 4
                </h5>
                <p>
                  There are many Locations of Lorem passages of Lorem Ipsum
                  available, but the majority.
                </p>
              </div>
              <div className="p-5 border border-troo-bluefade shadow-sm rounded">
                <h5 className="flex items-center gap-2 mb-4">
                  <GrLocation /> Location 5
                </h5>
                <p>
                  There are many Locations of Lorem passages of Lorem Ipsum
                  available, but the majority.
                </p>
              </div>
              <div className="p-5 border border-troo-bluefade shadow-sm rounded">
                <h5 className="flex items-center gap-2 mb-4">
                  <GrLocation /> Location 6
                </h5>
                <p>
                  There are many Locations of Lorem passages of Lorem Ipsum
                  available, but the majority.
                </p>
              </div>
            </div>
            <h3>Photo Gallery</h3>
            <div className="grid grid-cols-1 gap-5 w-full md:grid-cols-2 lg:grid-cols-3">
              {GalleryData.slice(5, 11).map((e, i) => (
                <Link key={i}>
                  <img className="w-full" src={e.img} alt={e.img} />
                </Link>
              ))}
            </div>
            <h3>Good to Know</h3>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text.
            </p>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary, making
              this the first true generator on the Internet.
            </p>
          </div>
          <div className="w-full md:sticky top-40 lg:w-[25%] flex flex-col items-start gap-4">
            <div className="border border-troo-hrtag  w-full">
              <h4 className="bg-troo-secondary text-center text-troo-white p-5">
                Make an Appointment
              </h4>
              <div className="px-5 py-5 flex flex-col gap-3 ">
                <p>Full Name</p>
                <input
                  type="text"
                  placeholder="Full Name"
                  className="p-2 bg-transparent border border-troo-hrtag rounded w-full"
                />
                <p>Enter Email</p>
                <input
                  type="email"
                  placeholder="Your Email Address"
                  className="p-2 bg-transparent border border-troo-hrtag rounded w-full"
                />
                <p>Enter Phone No</p>
                <input
                  type="number"
                  placeholder="+44 133 466 7890"
                  className="p-2 bg-transparent border border-troo-hrtag rounded w-full"
                />
                <p>Choose Country</p>
                <select className="p-2">
                  <option>Afaghanistan</option>
                  <option>Pakistan</option>
                  <option>India</option>
                </select>
                <p>Write Message</p>
                <textarea
                  placeholder="White something to me"
                  className="p-5 bg-transparent border rounded border-troo-hrtag w-full"
                  rows={4}
                />
                <Link className="btn3 mt-5 text-center">Book Now!</Link>
              </div>
            </div>
            <div className="border border-troo-bluefade  w-full">
                <h3 className="bg-troo-secondary text-center text-troo-white p-5">
                  Other Services
                </h3>
                <div className="px-5 flex flex-col ">
                  <p className="border-b border-b-troo-hrtag py-3">
                    Web Development
                  </p>
                  <p className="border-b border-b-troo-hrtag py-3">
                    Graphic Design
                  </p>
                  <p className="border-b border-b-troo-hrtag py-3">
                    Digital Marketing
                  </p>
                  <p className="border-b border-b-troo-hrtag py-3">SEO</p>
                  <p className="border-b border-b-troo-hrtag py-3">
                    Game Development
                  </p>
                  <p className="border-b border-b-troo-hrtag py-3">
                    Animation{" "}
                  </p>
                  <p className="border-b border-b-troo-hrtag py-3">
                    Web Hosting{" "}
                  </p>
                  <p className="py-3">Video Editing </p>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DestinationDetails;
