import React from "react";
import highlightRight from "../images/highlightleftImg.png";
import { TbDiscountCheck } from "react-icons/tb";

const HighLights = () => {
  return (
    <div className="highlight grid grid-cols-1 lg:grid-cols-2 ">
      <div>
        <img className="w-full h-full" src={highlightRight} alt={highlightRight} />
      </div>
      <div className="bg-HighlightRIght-bg bg-no-repeat w-full bg-center bg-contain bg-troo-deepblack pe-8 lg:pe-0 ps-8 text-troo-white py-16">
        <p className="bg-troo-borderclr py-2 px-6 rounded-full mb-5  inline-block">
          Why Choose Us
        </p>
        <h2 className="mb-5 max-w-[700px] ">
          We Are The
          <span className="text-troo-primary"> Best In Tour - Travel </span>
          Business Sinse 30+ Years
        </h2>
        <p className="max-w-[600px] mb-8">
          It is a long established fact that it is also reader we arewill be
          distracted by the readable content of a page when looking at its
          layout. The point of using Lorem Ipsum is just dummy content.
        </p>
        <div className="lg:max-w-[600px] grid grid-cols-1 md:grid-cols-2 gap-5">
          <div className="rounded flex border border-troo-borderclr p-4 gap-3 hover:bg-troo-borderclr">
            <div>
              <TbDiscountCheck className="text-[24px] text-troo-primary"/>
            </div>
            <div>
              <h4 className="mb-3">Safe Tour Guide</h4>
              <p>It is a long established fact that it is also reade.</p>
            </div>
          </div>
          <div className="rounded flex border border-troo-borderclr p-4 gap-3 hover:bg-troo-borderclr">
            <div>
              <TbDiscountCheck className="text-[24px] text-troo-primary"/>
            </div>
            <div>
              <h4 className="mb-3">Safe Tour Guide</h4>
              <p>It is a long established fact that it is also reade.</p>
            </div>
          </div>
          <div className="rounded flex border border-troo-borderclr p-4 gap-3 hover:bg-troo-borderclr">
            <div>
              <TbDiscountCheck className="text-[24px] text-troo-primary"/>
            </div>
            <div>
              <h4 className="mb-3">Safe Tour Guide</h4>
              <p>It is a long established fact that it is also reade.</p>
            </div>
          </div>
          <div className="rounded flex border border-troo-borderclr p-4 gap-3 hover:bg-troo-borderclr">
            <div>
              <TbDiscountCheck className="text-[24px] text-troo-primary"/>
            </div>
            <div>
              <h4 className="mb-3">Safe Tour Guide</h4>
              <p>It is a long established fact that it is also reade.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HighLights;
