import React from "react";
import { BlogData } from "../Data/BlogData";
import { Link, createSearchParams } from "react-router-dom";

const Blog3 = () => {
  return (
    <div className="py-12 lg:py-24 bg-troo-bluefade">
      <div className="main_container">
        <h2 className="text-troo-secondary mb-7">Related Blogs</h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          {BlogData.slice(1, 3).map((e, i) => (
            <Link
              to={`/our-blogs/blog-details?${createSearchParams({ id: e.id })}`}
              className="grid grid-cols-1 lg:grid-cols-2 border border-troo-bluefade rounded"
              key={i}
            >
              <div>
                <img className="w-full" src={e.img} alt={e.img} />
              </div>
              <div className="px-3 py-5 flex flex-col gap-5">
                <h6>{e.date}</h6>
                <h4>{e.name}</h4>
                <p>{e.para}</p>
                <div className="h-[1px]  bg-troo-bluefade"></div>
                <p>{e.sub}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog3;
