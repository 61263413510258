import React from "react";
import { AboutHighlightData } from "../Data/AboutHighlightData";

const AboutHighlight = () => {
  return (
    <div className=" bg-about-highBg bg-no-repeat w-full bg-troo-tertiary bg-center  py-12 lg:py-24">
      <div className="main_container">
        <div className="text-center mb-10">
          <p className="bg-troo-headingbg py-2 px-6 rounded-full mb-5  inline-block">
            We Are The Best
          </p>
          <h2 className="max-w-[500px] m-auto">
            We Have a
            <span className="text-troo-secondary"> Great History </span>to Start
            Our Company.
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 ">
          {AboutHighlightData.map((e, i) => (
            <div className="bg-troo-white flex flex-col items-center gap-5 py-10 px-5 group rounded border border-white hover:border-troo-bluefade">
              <div className="bg-troo-bluefade rounded-[50%] p-3">
                <img src={e.img} alt="happy" />
              </div>
              <h3 className=" ">{e.name}</h3>
              <p className="text-center">{e.para}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutHighlight;
