import React from "react";
import { Link, createSearchParams } from "react-router-dom";
import { TeamData } from "../Data/TeamData";

const TeamsComponent = () => {
  return (
    <div className="bg-team-bg bg-no-repeat w-full bg-center bg-troo-tertiary pt-12 lg:pt-24 pb-36 lg:pb-48">
      <div className="main_container ">
        <p className="bg-troo-headingbg py-2 px-6 rounded-full mb-5  inline-block">
          Our Popular Members
        </p>
        <div className="flex flex-col lg:flex-row w-full justify-between gap-6 mb-12">
          <h2 className="w-full">
          We Have <span className="text-troo-secondary"> Guide Team </span>
          With Completed Training
          </h2>
          <p className="flex-grow w-full">
            Contrary to popular belief, Lorem Ipsum is not simply random
            text.has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old.
          </p>
          <div className="w-full lg:flex flex-col items-end ">
            <Link to="/our-team" className="btn4 ">View All Members</Link>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
          {TeamData.slice(0,4).map((e, i) => (
            <Link to={`/our-team/our-team-details?${createSearchParams({id : e.id})}`} className=" bg-troo-white p-1 group " key={i}>
              <div className="relative flex justify-center overflow-hidden w-full group after:content-[''] hover:after:bg-troo-bluefade2 after:w-full after:absolute after:top-0 after:left-0 after:h-full after:z-10 before:bg-opacity-0 group-hover:before:bg-opacity-10">
                <img className="w-full" src={e.img} alt={e.img} />
                <div className="absolute bottom-3 flex gap-3 bg-troo-white p-2 rounded z-20 opacity-0 group-hover:opacity-100 duration-500 transition-all scale-0 group-hover:scale-100">
                  {e.social.map((icon, i) => (
                    <div className="hover:bg-troo-secondary hover:text-troo-white rounded p-1" key={i}>{icon.icon}</div>
                  ))}
                </div>
              </div>
              <h3 className="text-center pt-3 group-hover:text-troo-secondary">{e.name}</h3>
              <p className="text-center py-2" >{e.sub}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamsComponent;
