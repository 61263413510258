import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import tourDetails from "../images/tourDetails.png";
import tourDetails2 from "../images/tourDetails2.png";
import { TbDiscountCheck } from "react-icons/tb";
import { FaqData } from "../Data/FAQData";
import Accordian from "./Accordian";
import { TourData } from "../Data/TourData";

const TourDetails = () => {
  const [heading, setHeading] = useState("")
  const [picture, setPicture] = useState("")
  const [scarchparam] =useSearchParams()
  const id = scarchparam.get("id")

  useEffect(()=>{
    const filData = TourData.find((data)=>data?.id == id)
    setHeading(filData?.name)
    setPicture(filData?.img)

  },[id])
  return (
    <div className=" w-full py-12 lg:py-24 ">
      <div className="main_container">
        <div className="flex flex-col lg:flex-row h-full items-start gap-6">
          <div className="w-full lg:w-[75%] flex flex-col items-start gap-5">
            <img className="w-full max-h-[550px]" src={ picture || tourDetails} alt="tourDetails" />
            <h2>{heading || "Dubai – Amazing Dubai Desert and City Tour" }</h2>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority going have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. going All the Lorem Ipsum generators
              on the Internet tend to repeat predefined chunks as necessary,
              making this the first true generator on the Internet
            </p>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority going have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum.
            </p>
            <div className="w-full grid grid-cols-2 md:grid-cols-4 lg:flex items-center justify-around p-3 border border-troo-bluefade rounded shadow-sm">
              <div className="border-r border-troo-bluefade px-5 py-2">
                <h5>From</h5>
                <p>$150.00</p>
              </div>
              <div className="border-r border-troo-bluefade px-5 py-2">
                <h5>Duration</h5>
                <p>3 days</p>
              </div>
              <div className="border-r border-troo-bluefade px-5 py-2">
                <h5>Max People</h5>
                <p>36 People</p>
              </div>
              <div className="border-r border-troo-bluefade px-5 py-2">
                <h5>Pickup</h5>
                <p>Airpot</p>
              </div>
              <div className="border-r border-troo-bluefade px-5 py-2">
                <h5>Wifi</h5>
                <p>Free WiFi</p>
              </div>
              <div className="border-r border-troo-bluefade px-5 py-2">
                <h5>Start Date</h5>
                <p>20 Jan, 2023</p>
              </div>
              <div className="border-r border-troo-bluefade px-5 py-2">
                <h5>End Date</h5>
                <p>24 Jan, 2023</p>
              </div>
            </div>
            <h3>Description</h3>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority going have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum.
            </p>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority going have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. going All the Lorem Ipsum
              generators.
            </p>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority going have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. going All the Lorem Ipsum generators
              on the Internet tend to repeat predefined chunks as necessary,
              making this the first true generator on the Internet
            </p>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
              <div>
                <h3 className="mb-3">Hightlights</h3>
                <div className="flex flex-col gap-1 ms-4">
                  <p className="flex items-center gap-2">
                    <TbDiscountCheck /> 150 years of experience{" "}
                  </p>
                  <p className="flex items-center gap-2">
                    <TbDiscountCheck /> Quality & durability according to your
                    wishes.{" "}
                  </p>
                  <p className="flex items-center gap-2">
                    <TbDiscountCheck /> Two modern showrooms for consultations{" "}
                  </p>
                  <p className="flex items-center gap-2">
                    <TbDiscountCheck /> Our own joinery in Bern{" "}
                  </p>
                  <p className="flex items-center gap-2">
                    <TbDiscountCheck /> Two modern showrooms for consultations{" "}
                  </p>
                  <p className="flex items-center gap-2">
                    <TbDiscountCheck /> Our own joinery in Bern{" "}
                  </p>
                </div>
              </div>
              <div>
                <img className="w-full" src={tourDetails2} alt="tourDetails" />
              </div>
            </div>
            <h3>Tour Plan</h3>
            <div className="flex flex-col gap-4 items-start">
              {FaqData.slice(0, 5).map((data, i) => {
                return <Accordian key={i} title={data.name} desc={data.para} />;
              })}
            </div>
          </div>
          <div className="w-full md:sticky top-40 lg:w-[25%] flex flex-col items-start gap-4">
            <div className="border border-troo-hrtag  w-full">
              <h4 className="bg-troo-secondary text-center text-troo-white p-5">
                Make an Appointment
              </h4>
              <div className="px-5 py-5 flex flex-col gap-3 ">
                <p>Full Name</p>
                <input
                  type="text"
                  placeholder="Full Name"
                  className="p-2 bg-transparent border border-troo-hrtag rounded w-full"
                />
                <p>Enter Email</p>
                <input
                  type="email"
                  placeholder="Your Email Address"
                  className="p-2 bg-transparent border border-troo-hrtag rounded w-full"
                />
                <p>Enter Phone No</p>
                <input
                  type="number"
                  placeholder="+44 133 466 7890"
                  className="p-2 bg-transparent border border-troo-hrtag rounded w-full"
                />
                <p>Choose Country</p>
                <select className="p-2">
                  <option>Afaghanistan</option>
                  <option>Pakistan</option>
                  <option>India</option>
                </select>
                <p>Write Message</p>
                <textarea
                  placeholder="White something to me"
                  className="p-5 bg-transparent border rounded border-troo-hrtag w-full"
                  rows={4}
                />
                <Link className="btn3 text-center mt-5">Book Now!</Link>
              </div>
            </div>
            <div className="border border-troo-bluefade  w-full">
              <h3 className="bg-troo-secondary text-center text-troo-white p-5">
                Other Services
              </h3>
              <div className="px-5 flex flex-col ">
                <p className="border-b border-b-troo-hrtag py-3">
                  Web Development
                </p>
                <p className="border-b border-b-troo-hrtag py-3">
                  Graphic Design
                </p>
                <p className="border-b border-b-troo-hrtag py-3">
                  Digital Marketing
                </p>
                <p className="border-b border-b-troo-hrtag py-3">SEO</p>
                <p className="border-b border-b-troo-hrtag py-3">
                  Game Development
                </p>
                <p className="border-b border-b-troo-hrtag py-3">Animation </p>
                <p className="border-b border-b-troo-hrtag py-3">
                  Web Hosting{" "}
                </p>
                <p className="py-3">Video Editing </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourDetails;
