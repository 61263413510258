import React from 'react'
import { TeamData } from '../Data/TeamData'
import { Link, createSearchParams } from 'react-router-dom'

const TeamComponent2 = () => {
  return (
    <div className='py-12 lg:py-24'>
          <div className="main_container ">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
          {TeamData.map((e, i) => (
              <Link to={`/our-team/our-team-details?${createSearchParams({id : e.id})}`} className=" bg-troo-white p-1 group " key={i}>
              <div className="relative flex justify-center overflow-hidden w-full group after:content-[''] hover:after:bg-troo-bluefade2 after:w-full after:absolute after:top-0 after:left-0 after:h-full after:z-10 before:bg-opacity-0 group-hover:before:bg-opacity-10">
                <img className="w-full" src={e.img} alt={e.img} />
                <div className="absolute bottom-3 flex gap-3 bg-troo-white p-2 rounded z-20 opacity-0 group-hover:opacity-100 duration-500 transition-all scale-0 group-hover:scale-100">
                  {e.social.map((icon, i) => (
                    <div className="hover:bg-troo-secondary hover:text-troo-white rounded p-1" key={i}>{icon.icon}</div>
                  ))}
                </div>
              </div>
              <h3 className="text-center pt-3 group-hover:text-troo-secondary">{e.name}</h3>
              <p className="text-center py-2" >{e.sub}</p>
            </Link>
          ))}
        </div>
          </div>
        
    </div>
  )
}

export default TeamComponent2