import React from 'react'
import Layout from '../Components/Layout'
import Banner from '../Components/Banner'
import AboutComponent from '../Components/AboutComponent'
import Destination from '../Components/Destination'
import VideoSection from '../Components/VideoSection'
import TravelCategory from '../Components/TravelCategory'
import TourPackage from '../Components/TourPackage'
import Counters from '../Components/Counters'
import HighLights from '../Components/HighLights'
import Feedback from '../Components/Feedback'
import TeamsComponent from '../Components/TeamsComponent'
import Highlight2 from '../Components/Highlight2'
import FAQs from '../Components/FAQs'
import Gallerys from '../Components/Gallerys'
import Blogs from '../Components/Blogs'
import useDocumentTitle from '../Hooks/PageTitle'

const Home = () => {
  useDocumentTitle("TRoo Traveling - React Js Landing Page Template")
  return (
    <Layout>
        <Banner/>
        <AboutComponent/>
        <Destination/>
        <VideoSection/>
        <TravelCategory/>
        <TourPackage/>
        <Counters/>
        <HighLights/>
        <Feedback/>
        <TeamsComponent/>
        <Highlight2/>
        <FAQs/>
        <Gallerys/>
        <Blogs/>
    </Layout>
  )
}

export default Home