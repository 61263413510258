import React, { useEffect, useState } from "react";
import teamDetails from "../images/teamDetails.png";
import { useSearchParams } from "react-router-dom";
import { TeamData } from "../Data/TeamData";

const TeamDetails = () => {
  const [heading, setHeading] = useState("")
  const [picture, setPicture] = useState("")
  const [sub, setSub] = useState("")
  const [scarchparam] =useSearchParams()
  const id = scarchparam.get("id")

  useEffect(()=>{
    const filData = TeamData.find((data)=>data?.id == id)
    setHeading(filData?.name)
    setPicture(filData?.img)
    setSub(filData?.sub)

  },[id])
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 ">
          <div className="p-2 border border-troo-bluefade shadow-sm">
            <img
              className="w-full rounded object-contain max-h-[500px]"
              src={picture || teamDetails}
              alt="teamDetails"
            />
          </div>
          <div className="flex flex-col gap-3">
            <h3 className="text-troo-secondary">{heading || "Patty O’Furniture"}</h3>
            <h6>{ sub || "CEO and Founder"}</h6>
            <p>
              Phone No: <span className="font-semibold"> +44 132 456 7890</span>
            </p>
            <p>
              Email Address:
              <span className="font-semibold"> pattyofurniture@troo.com</span>
            </p>
            <div className="h-[1px] w-full bg-troo-bluefade shadow-sm"></div>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting,
            </p>
            <h3>Experience & Activities</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s.
            </p>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting,
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamDetails;
