import React, { useEffect, useRef } from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import logo from "../images/Logo.svg";
import { HeaderData } from "../Data/HeaderData";
import NavMenu from "./NavMenu";
import humburger from "../images/Humburgermenu.svg";
import { sidebarData } from "../Data/SideDarData";
import { RiMenu3Fill } from "react-icons/ri";
import AccordianMenu from "./AccordianMenu";

const Header = () => {
  const location = useLocation();
  let arr = location.pathname?.split("/");
  let lastvalue = arr[arr.length - 1].trim().replace(/-/g, " ");

  const navbar_ref = useRef();
  function handleScroll() {
    if (window.pageYOffset > 10 || document.documentElement.scrollTop > 10) {
      if (navbar_ref.current) {
        navbar_ref.current.style.top = "0px";
      }
    } else {
      if (navbar_ref.current) {
        navbar_ref.current.style.top = "-150px";
      }
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="show_nav" ref={navbar_ref}>
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <div className="main_container ">
          <nav className="py-4">
            <div className="flex justify-between">
              <div className="mr-7 xl:mr-32">
                <Link to="/">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <div className="lg:flex hidden items-center ">
                <ul className="flex items-center h-full">
                  {HeaderData.slice(0, 6).map((item, i) => {
                    return <NavMenu key={i} item={item} />;
                  })}
                </ul>
                <div className="">
                  {HeaderData.slice(-1).map((e, i) => {
                    return (
                      <Link
                        key={i}
                        to={e.path}
                        className="btn1 text-troo-white"
                      >
                        {e.title}
                      </Link>
                    );
                  })}
                </div>
              </div>
              <div className="md:block lg:hidden text-troo-white drawer-content">
                <label htmlFor="my-drawer-2">
                  <RiMenu3Fill className="text-[30px] cursor-pointer" />
                </label>
              </div>
            </div>
          </nav>
      
        </div>
        <div className="drawer-side z-100">
            <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
            <div className="menu flex flex-col p-4 w-[80%] md:w-80 h-full overflow-scroll  bg-troo-deepblack text-troo-white">
              <div className="flex w-full flex-col items-start gap-4 lg:gap-6 2xl:gap-8">
                <div className="flex items-center">
                  <Link to="/">
                    <img
                      src={logo}
                      alt="logo"
                      className="w-[200px] md:w-[200px] xl:w-[250px]"
                    />
                  </Link>
                </div>
                <div className="flex w-full flex-col items-start gap-2 lg:gap-6 2xl:gap-8">
                  {HeaderData.slice(0, 6).map((e, i) => {
                    return !e?.subNav ? (
                      <Link
                        key={i}
                        to={e.path}
                        className={`py-1 flex items-center capitalize text-lg ${
                          e.title === lastvalue ? "active" : ""
                        }`}
                      >
                        {e.title}
                      </Link>
                    ) : (
                      <AccordianMenu title={e.title} subMenu={e.subNav} />
                    );
                  })}
                </div>
                <div className="">
                  {HeaderData.slice(-1).map((e, i) => {
                    return (
                      <Link
                        key={i}
                        to="/hire-me"
                        className="btn4"
                      >
                        {e.title}
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className="drawer drawer-end">
        <input id="my-drawer-1" type="checkbox" className="drawer-toggle" />
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <header className="main_container  absolute w-full left-[50%] translate-x-[-50%] z-10">
          <div className="top_header md:flex hidden justify-between py-5 border-b border-dashed border-troo-whitefade2 pb-5">
            <div className="flex gap-4 text-troo-white">
              <p>Phone: +44 123 456 7890</p>
              <p>Email: info@troothemes.com</p>
            </div>
            <div className="flex items-center gap-1 text-troo-white">
              <p>We Are on :</p>
              <Link className="hover:bg-troo-primary p-2 hover:text-troo-white rounded">
                <FaFacebookF />
              </Link>
              <Link className="hover:bg-troo-primary p-2 hover:text-troo-white rounded">
                <FaInstagram />
              </Link>
              <Link className="hover:bg-troo-primary p-2 hover:text-troo-white rounded">
                <FaTwitter />
              </Link>
              <Link className="hover:bg-troo-primary p-2 hover:text-troo-white rounded">
                <FaLinkedinIn />
              </Link>
              <div className="drawer-content ms-5">
                <label htmlFor="my-drawer-1" className="drawer-button">
                  <img src={humburger} alt="humburger" />
                </label>
              </div>
            </div>
          </div>
          <nav className="py-4">
            <div className="flex justify-between">
              <div className="mr-7 xl:mr-32">
                <Link to="/">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <div className="lg:flex hidden items-center ">
                <ul className="flex items-center h-full">
                  {HeaderData.slice(0, 6).map((item, i) => {
                    return <NavMenu key={i} item={item} />;
                  })}
                </ul>
                <div className="">
                  {HeaderData.slice(-1).map((e, i) => {
                    return (
                      <Link
                        key={i}
                        to={e.path}
                        className="btn1 text-troo-white"
                      >
                        {e.title}
                      </Link>
                    );
                  })}
                </div>
              </div>
              <div className="md:block lg:hidden text-troo-white drawer-content">
                <label htmlFor="my-drawer-2">
                  <RiMenu3Fill className="text-[30px] cursor-pointer" />
                </label>
              </div>
            </div>
          </nav>
        </header>
        <div className="drawer-side z-50">
          <label htmlFor="my-drawer-1" className="drawer-overlay"></label>
          <div className="menu  min-h-full bg-base-200 text-base-content">
            <div className="p-6 bg-troo-secondary">
              <h3 className="text-troo-white text-center leading-relaxed m-auto max-w-[300px]">
                Best Packages for Your Dream Destination
              </h3>
            </div>
            <div className="p-5 flex flex-col gap-4">
              {sidebarData.map((e, i) => (
                <Link
                  className="grid grid-cols-2 border border-troo-bluefade shadow-sm"
                  key={i}
                >
                  <div>
                    <img src={e.img} alt={e.img} className="w-[90%] h-40" />
                  </div>
                  <div className="py-5 ">
                    <h4 className="mb-4">{e.name}</h4>
                    <div className="flex items-center gap-3 mb-2">
                      {e.location}
                      {e.locationName}
                    </div>
                    <div className="flex items-center gap-3 mb-2">
                      {e.coin}
                      {e.price}
                      {e.disCount}
                    </div>
                    <div className="flex items-center gap-3 mb-2">
                      {e.clock}
                      {e.day}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
            <div className="flex justify-center px-5 py-10">
              <div className="flex gap-5">
                <Link className="btn3">View All Packages</Link>
                <Link className="btn4">Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </>
  );
};

export default Header;
