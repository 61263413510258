import React from 'react'
import Layout from '../Components/Layout'
import BannerGlobal from '../Components/BannerGlobal'
import Contacts from '../Components/Contacts'
import useDocumentTitle from '../Hooks/PageTitle'

const ContactPage = () => {
  useDocumentTitle("TRoo Traveling | Contact Page")
  return (
    <div>
        <Layout>
            <BannerGlobal/>
            <Contacts/>
        </Layout>
    </div>
  )
}

export default ContactPage