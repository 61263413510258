import React from "react";
import { Link } from "react-router-dom";
import { FiPlay } from "react-icons/fi";

const VideoSection = () => {
  return (
    <div className="bg-video-bg w-full bg-no-repeat bg-center bg-cover relative z-[1]  pt-20 pb-44 lg:pb-60 before:content-[''] before:w-full before:h-full before:absolute before:bg-troo-deepblack before:opacity-80 before:top-0 before:left-0 before:z-[-1]">
      <div className="main_container grid grid-cols-1 lg:grid-cols-2">
        <div className="text-troo-white">
          <p className="bg-troo-whitefade py-2 px-6 rounded-full mb-5  inline-block">
            Feel The Vibe
          </p>
          <h2 className="mb-5 max-w-[500px]">
            Life Begins at The End of Your
            <span className="text-troo-primary"> Comfort Zone</span>
          </h2>
          <p className="mb-8 max-w-[500px]">
            Lorem ipsum dolor sit amet, consectetur adi- piscing elit. sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim. Exercitation ullam laboris nisi ut aliquip ex ea commodo
            consequat.
          </p>
          <Link to="/about-me" className="btn2 bg-troo-primary">
            Explore More
          </Link>
        </div>

        <div className="flex justify-center items-center mt-10 lg:mt-0  lg:justify-end">
          <div className="text-troo-white flex items-center gap-3 lg:gap-7">
            <h3>Watch the Video</h3>
            <div className="line"></div>
            <div
              className="videoIcon"
              onClick={() => window.my_modal_2.showModal()}
            >
              <FiPlay />
            </div>
          </div>
          <dialog id="my_modal_2" className="modal">
            <form
              method="dialog"
              className="modal-box w-11/12 max-w-5xl p-0 overflow-hidden"
            >
              <iframe
                width="100%"
                height="500"
                src="https://www.youtube.com/embed/DUfXdcpEfMs"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </form>
            <form method="dialog" className="modal-backdrop">
              <button>close</button>
            </form>
          </dialog>
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
