import React from "react";
import Accordian from "./Accordian";
import { FaqData } from "../Data/FAQData";
import { Link } from "react-router-dom";

const FAQs = () => {
  return (
    <div className="faq py-12 lg:py-24">
      <div className="main_container">
        <div className="text-center">
          <p className="bg-troo-headingbg py-2 px-6 rounded-full mb-5  inline-block">
            Have You Got Question?
          </p>
          <h2 className="mb-5 ">Frequently Asked Questions.</h2>
        </div>
        <div className="lg:flex gap-6 py-10">
            <div className="flex flex-col gap-4 items-start">
              {FaqData.slice(0, 6).map((data, i) => {
                return <Accordian key={i} title={data.name} desc={data.para} />;
              })}
            </div>
            <div className="flex flex-col gap-4 items-start">
              {FaqData.slice(6, 12).map((data, i) => {
                return <Accordian key={i} title={data.name} desc={data.para} />;
              })}
            </div>
          </div>
          <div className="mt-10 text-center">
            <Link to="/faq" className="btn1 text-troo-deepblack">View All Quistions</Link>

          </div>
      </div>
    </div>
  );
};

export default FAQs;
