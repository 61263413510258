import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import FeedBack2 from '../../Components/FeedBack2'
import useDocumentTitle from '../../Hooks/PageTitle'

const TestimonialPage = () => {
  useDocumentTitle("TRoo Traveling | Testimonial Page")
  return (
    <div>
        <Layout>
            <BannerGlobal/>
            <FeedBack2/>
        </Layout>
    </div>
  )
}

export default TestimonialPage