import React from "react";
import about from "../images/about.png";
import { Link } from "react-router-dom";
import chat from "../images/chatIcon.png";
import ScarchBox from "./ScarchBox";

const AboutComponent = () => {
  return (
    <div className="about ">
      <ScarchBox />
      <div className="main_container py-12 lg:py-24">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="py-12 relative flex justify-center">
            <img className="w-full" src={about} alt="about" />
            <div className="absolute bg-troo-secondary gap-3 rounded py-5 px-5 xl:px-10 flex md:flex-row flex-col items-center bottom-[-50px] lg:bottom-0">
              <img className="w-16" src={chat} alt="chat" />
              <div className="text-troo-white ">
                <p className="text-center md:text-start">Let’s talk with us</p>
                <p className="abtnum text-center md:text-start">+44 123 456 7890</p>
                <p className="abtnum text-center md:text-start">info@troothemes.com</p>
              </div>
            </div>
          </div>
          <div className="py-12">
            <p className="bg-troo-headingbg py-2 px-6 rounded-full mb-5  inline-block">
              Tours & Holidays Specialists
            </p>
            <h2 className="mb-5">
              We’re Most Trusted <br />
              <span className="text-troo-secondary"> Travel Partner </span>
              Around The World
            </h2>
            <h4 className="mb-5">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. We are Ut
              elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
            </h4>
            <div className="flex gap-5 mb-6">
              <div className="bg-troo-tertiary p-5">
                <h4 className="mb-5 pt-3 relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-9 before:bg-troo-secondary before:h-[2px] ">
                  Accurate Guidance
                </h4>
                <p>
                  There are many variations we are of randomised words which ok
                  even slightly believable.
                </p>
              </div>
              <div className="bg-troo-tertiary p-5">
                <h4 className="mb-5 pt-3 relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-9 before:bg-troo-secondary before:h-[2px]">
                  Trusted By 30+ Tour Agency
                </h4>
                <p>
                  There are many variations we are of randomised words which ok
                  even slightly believable.
                </p>
              </div>
            </div>
            <p className="mb-8">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable.
            </p>
            <Link to="/about-me" className="btn3">Learn More</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutComponent;
