export const HeaderData = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "about me",
    path: "/about-me",
    heading: "Learn About US",
  },
  {
    title: "destinations",
    path: "#",
    subNav: [
      {
        subTitle: "destinations",
        heading: "Popular Destination",
        path: "/destinations",
      },
      {
        subTitle: "destinations details",
        heading: "Destination Detail Page",
        path: "/destinations/destinations-details",
      },
    ],
  },
  {
    title: "Pages",
    path: "#",
    subNav: [
      {
        subTitle: "tour package",
        heading: "Our Tour Packages",
        path: "/tour-package",
      },
      {
        subTitle: "tour package details",
        heading: "Tour Package Detail",
        path: "/tour-package/tour-package-details",
      },
      {
        subTitle: "testimonial",
        heading: "Awesome Clients Feedback",
        path: "/testimonial",
      },
      {
        subTitle: "our team",
        heading: "Our Awesome Team Member",
        path: "/our-team",
      },
      {
        subTitle: "our team details",
        heading: "Team Detail Page",
        path: "/our-team/our-team-details",
      },
      {
        subTitle: "faq",
        heading: "Frequently Asked Questions ",
        path: "/faq",
      },
      {
        subTitle: "error 404",
        path: "/error-404",
      },
      {
        subTitle: "coming soon",
        path: "/coming-soon",
      },
    ],
  },
  {
    title: "Blogs & Guides",
    path: "#",
    subNav: [
      {
        subTitle: "our blogs",
        heading: "News, Tips & Guides",
        path: "/our-blogs",
      },
      {
        subTitle: "blog details",
        heading: "Blog Detail Page",
        path: "/our-blogs/blog-details",
      },
    ],
  },
  {
    title: "contact us",
    path: "/contact-us",
    heading: "Get in Touch",
  },
  {
    title: "plan my tour",
    heading: "Book an Appointment",
    path: "/plan-my-tour",
  },
];
