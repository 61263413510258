import img1 from "../images/sidebarImg1.png";
import img2 from "../images/sidebarImg2.png";
import img3 from "../images/sidebarImg3.png";
import img4 from "../images/sidebarImg4.png";
import { CiLocationOn } from "react-icons/ci";
import { LiaCoinsSolid } from "react-icons/lia";
import { FaRegClock } from "react-icons/fa";

export const sidebarData = [
  {
    id: 1,
    img: img1,
    name: "Family Tour With Dubai!",
    clock: <FaRegClock />,
    coin: <LiaCoinsSolid />,
    location: <CiLocationOn />,
    locationName: "United Arab Emirates ",
    price: "From $670.00",
    disCount: "$890.00",
    day: "4 Days / 5 Nights",
  },
  {
    id: 2,
    img: img2,
    name: "Family Tour With India!",
    clock: <FaRegClock />,
    coin: <LiaCoinsSolid />,
    location: <CiLocationOn />,
    locationName: "New Delhi, India ",
    price: "From $670.00",
    disCount: "$890.00",
    day: "4 Days / 5 Nights",
  },
  {
    id: 3,
    img: img3,
    name: "Family Tour With Nepal!",
    clock: <FaRegClock />,
    coin: <LiaCoinsSolid />,
    location: <CiLocationOn />,
    locationName: "Kathmandu, Nepal ",
    price: "From $670.00",
    disCount: "$890.00",
    day: "4 Days / 5 Nights",
  },
  {
    id: 4,
    img: img4,
    name: "Family Tour With Bhutan!",
    clock: <FaRegClock />,
    coin: <LiaCoinsSolid />,
    location: <CiLocationOn />,
    locationName: "Imphal, Bhutan",
    price: "From $670.00",
    disCount: "$890.00",
    day: "4 Days / 5 Nights",
  },
];
