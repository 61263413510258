import destination1 from "../images/destination1.png";
import destination2 from "../images/destination2.png";
import destination3 from "../images/destination3.png";
import destination4 from "../images/destination4.png";
import destination5 from "../images/destination5.png";
import destination6 from "../images/destination6.png";
import destination7 from "../images/destination7.png";
import destination8 from "../images/destination8.png";
import destination9 from "../images/destination9.png";
import destination10 from "../images/destination10.png";
import destination11 from "../images/destination11.png";
import destination12 from "../images/destination12.png";

export const DestinationData = [
  {
    id: 1,
    img: destination1,
    name: "Beauty of Delhi",
    para: "25 Trips",
  },
  {
    id: 2,
    img: destination2,
    name: "Beauty of Otty",
    para: "25 Trips",
  },
  {
    id: 3,
    img: destination3,
    name: "Beauty of USA",
    para: "25 Trips",
  },
  {
    id: 4,
    img: destination4,
    name: "Beauty of Monali",
    para: "25 Trips",
  },
  {
    id: 5,
    img: destination5,
    name: "Beauty of Singhapur",
    para: "25 Trips",
  },
  {
    id: 6,
    img: destination6,
    name: "Beauty of Simla",
    para: "25 Trips",
  },
  {
    id: 7,
    img: destination7,
    name: "Beauty of Odisha",
    para: "25 Trips",
  },
  {
    id: 8,
    img: destination8,
    name: "Beauty of Pune",
    para: "25 Trips",
  },
  {
    id: 9,
    img: destination9,
    name: "Beauty of Dubai",
    para: "25 Trips",
  },
  {
    id: 10,
    img: destination10,
    name: "Beauty of Honkang",
    para: "25 Trips",
  },
  {
    id: 11,
    img: destination11,
    name: "Beauty of Rajastan",
    para: "25 Trips",
  },
  {
    id: 12,
    img: destination12,
    name: "Beauty of Himachal",
    para: "25 Trips",
  },
];
