import React from "react";
import Layout from "../Components/Layout";
import BannerGlobal from "../Components/BannerGlobal";
import AboutComponent from "../Components/AboutComponent";
import AboutHighlight from "../Components/AboutHighlight";
import VideoSection from "../Components/VideoSection";
import TravelCategory from "../Components/TravelCategory";
import TourPackage from "../Components/TourPackage";
import Counters from "../Components/Counters";
import HighLights from "../Components/HighLights";
import Feedback from "../Components/Feedback";
import TeamsComponent from "../Components/TeamsComponent";
import Highlight2 from "../Components/Highlight2";
import Gallerys from "../Components/Gallerys";
import useDocumentTitle from "../Hooks/PageTitle";

const AboutPage = () => {
  useDocumentTitle("TRoo Traveling | About us")
  return (
    <div className="subPage">
      <Layout>
        <BannerGlobal />
        <AboutComponent />
        <AboutHighlight/>
        <VideoSection/>
        <TravelCategory/>
        <TourPackage/>
        <Counters/>
        <HighLights/>
        <Feedback/>
        <TeamsComponent/>
        <Highlight2/>
        <Gallerys/>
      </Layout>
    </div>
  );
};

export default AboutPage;
