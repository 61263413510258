import React, { useState } from "react";

const Accordian = ({ title, desc, bgColor }) => {
  const [isActive, setIsActive] = useState(false);

  const handleAccordionClick = (e) => {
    setIsActive(e.target.checked && !isActive);
  };
  return (
    <div
      className={`collapse collapse-plus cursor-pointer rounded border border-troo-bluefade ${
        isActive
          ? "text-troo-deepblack collapse-open "
          : `${
              bgColor || "text-troo-secondary"
            } text-troo-stateTertiary collapse-close`
      }`}
    >
      <input type="radio" name="my-accordion-1" onClick={handleAccordionClick} />
      <h5
        className={`collapse-title text-troo-deepblack font-medium px-3 md:px-4 flex gap-3
         ${isActive && "text-troo-white bg-troo-secondary mb-3 "}`}
      >
        <span>Q</span>
        {title}
      </h5>
      <div className="collapse-content px-4 flex gap-3">
        <span>A</span>
        <p>{desc}</p>
      </div>
    </div>
  );
};

export default Accordian;
